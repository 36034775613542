import React  from  'react';
import { Form } from 'react-final-form';
import Field from './../finalFormFields/Field';
import { Link } from "react-router-dom";
import {
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'

import TextInput from  './../finalFormFields/TextInput';

const SearchForm = ({ pushSearchButton = true, initialValues, onSubmit, filters, showSearch }) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => {
          const renderFilter = ({ name, label, component, componentProps }) => {
            return (
              <div
                key={name}
                className="col-12 col-md-6 col-xl-3"
              >
                <Field
                  component={component}
                  {...componentProps}
                  onChange={() =>  console.log(2)}
                />
              </div>
            );
          }

          return (
            <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                {showSearch && (
                  <div className="col-12 col-md-6 col-xl-3">
                    <Field
                      component={TextInput}
                      label='Αναζήτηση'
                      placeholder="Αναζήτηση..."
                      name="q"
                      type="text"
                      wrapperClass=""
                    />
                  </div>
                )}

                {filters.length > 0 && filters.map(f => renderFilter(f))}

                {(showSearch || filters?.length > 0) && (
                  <div className="col-12 col-md-auto mt-3 mt-md-0 d-flex flex-column">
                    {pushSearchButton && (
                      <label className="form-label">&ensp;</label>
                    )}
                    <button
                      className="btn btn-outline-dark ms-auto search-button"
                      type="submit"
                      disabled={submitting}
                    >
                      <MagnifyingGlassIcon className="ms-1" height={38} />
                    </button>
                  </div>
                )}
              </div>

              {submitError && <div className="error">{submitError}</div>}
            </form>
          );
        }
      }
    />
  );
};

export default SearchForm;
