import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { deleteDS } from '../dataServices/model';

const DeleteModal = ({ model, modelName, title, onDelete = () => {} }) => {
  const [deleteModal, setDeleteModal] = useState({ show: false });
  const dispatch = useDispatch();

  const showDeleteModal = (model) => {
    setDeleteModal({
      show: true,
    });
  };

  const hideDeleteModal = () => {
    setDeleteModal({
      show: false,
    });
  };

  const deleteItem = () => {
    return dispatch(deleteDS(modelName, model.id)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    }).then(() => {
      onDelete();
      hideDeleteModal();
    });
  };

  return (
    <>
      <Button
        className="ms-auto"
        variant="link text-danger"
        onClick={() => showDeleteModal()}
        size="sm"
      >
        { title || 'Διαγραφή'}
      </Button>

      <Modal
        enforceFocus
        backdrop="static"
        show={deleteModal.show}
        onHide={hideDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Επιβεβαίωση διαγραφής</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Είστε βέβαιος πως θέλετε να γίνει διαγραφή;
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={hideDeleteModal}>
            Ακύρωση
          </Button>
          <Button variant="danger" onClick={() => deleteItem()}>
            Διαγραφή
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
