import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import AsyncSelectInput from  './finalFormFields/AsyncSelectInput';
import { formatDate, formatMoneyAmount } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { createDS, updateDS } from '../dataServices/agreements';
import AgreementModalForm from './../components/forms/AgreementModalForm';
import DataGrid from './DataGrid';

import {
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';

const ContractorAgreementsTab = ({ contractor }) => {
  const model = `agreements/contractor/${contractor.id}`;
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [modal, setModal] = useState({ show: false, model: null });

  const hideModal = () => {
    setModal({
      model: null,
      show: false,
      readOnly: false,
    });
  }

  const showModal = (model, readOnly = false) => {
    setModal({
      model,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('agreements', values.id, values));
    } else {
      dataService = dispatch(createDS('agreements', values));
    }

    return dataService
      .then(({ data, status }) => {
        if (status >= 400 ) {
          return data;
        } else {
          toastr.success('Έγινε αποθήκευση');
          hideModal();
          loadData();
        }
      });
  }

  const columns = [
    {
      name: '#',
      render: (item) => (
        <a
          href="#"
          onClick={() => showModal(item, true)}
          className="list-item-link"
        >
          {item.title}
        </a>
      ),
    },
    {
      name: 'Ποσό',
      render: (item) => formatMoneyAmount(item.amount),
    },
    {
      name: 'Καταχωρήθηκε',
      key: 'createdAt',
      render: (item) => formatDate(item.createdAt),
    },
    {
      name: 'Έργο',
      key: 'projectId',
      render: (item) => {
        if (item?.project?.title) {
          return (
            <Link to={`/projects/${item?.projectId}`}>
              {item?.project?.title}
            </Link>
          )
        }

        return '-';
      },
    },
    // {
    //   name: 'Αρχείο',
    //   render: (item) => {
    //     if (!item.fileUrl) {
    //       return '-';
    //     }
    //
    //     const tooltip = (
    //       <Tooltip id="tooltip">
    //         Προβολή παραστατικού
    //       </Tooltip>
    //     );
    //
    //     return (
    //       <a
    //         target="_blank"
    //         href={`/api/${item.fileUrl}`}
    //       >
    //         <OverlayTrigger placement="top" overlay={tooltip}>
    //           <DocumentIcon width={25}/>
    //         </OverlayTrigger>
    //       </a>
    //     );
    //   }
    // },
    {
      name: '',
      render: (item) => (
        <div className="d-flex">
          <Button
            className="ms-auto"
            variant="outline-dark"
            onClick={() => showModal(item)}
            size="sm"
          >
            <PencilIcon className="ms-1" height={18} />
          </Button>
        </div>
      ),
    },
  ];

  if (!contractor) {
    return 'Φορτώνει..';
  }

  return (
    <>
      <DataGrid
        loadData={loadData}
        filters={[
          {
            component: AsyncSelectInput,
            componentProps: {
              label: 'Έργο',
              name: 'projectId',
              searchUrl: 'projects',
              multiselect: true,
            },
          },
        ]}
        addItemComponent={(
          <button
            className="btn btn-lg btn-primary ms-auto"
            onClick={() => showModal({ amount: null })}
            type="button"
          >
            <PlusIcon />
            Νέα συμφωνία
          </button>
        )}
        model={model}
        searchContainerClass="shadow-none"
        tableContainerClass="shadow-none mt-0"
        striped
        hover
        columns={columns}
        showDeleteButton
      />

      <AgreementModalForm
        initialValues={{
          contractorId: contractor.id,
          ...modal?.model,
          agreementType: 'contractor',
        }}
        onlyFor="contractor"
        onHide={hideModal}
        show={modal.show}
        readOnly={modal.readOnly}
        onSubmit={submitForm}
      />
    </>
  );
}

export default ContractorAgreementsTab;
