import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Tab, Tabs, Card } from 'react-bootstrap';
import {
  ChevronRightIcon
} from '@heroicons/react/24/outline'

import MeasurementVariationTab from './../components/MeasurementVariationTab';
import axiosClient from '../utils/axios';
import DefaultLayout from './../layouts/DefaultLayout';
import ProjectVerticalPropertiesTab from './../components/ProjectVerticalPropertiesTab';
import ProjectTransactionsTab from './../components/ProjectTransactionsTab';
import ProjectAgreementsTab from './../components/ProjectAgreementsTab';
import ProjectFilesTab from './../components/ProjectFilesTab';
import ProjectMeasurementsTab from './../components/ProjectMeasurementsTab';
import { MEASUREMENT_CATEGORIES, MEASUREMENT_CATEGORIES_LABELS } from '../constants/const';

const ProjectView = () => {
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId, type } = useParams()

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    const project = await axiosClient.get(`/projects/${projectId}`);
    setProject(project.data);
  };

  return (
    <DefaultLayout
      title={(
        <>
          {/*<Link className="text-muted" to="/projects">Έργα</Link>*/}
          {/*<ChevronRightIcon height={18} className="mx-1" />*/}
          <Link className="text-muted" to={`/projects/${projectId}`}>
            {project?.title}
          </Link>
          <ChevronRightIcon height={18} className="mx-1" />
          Επιμετρήσεις
        </>
      )}
    >
      {!project && 'Φορτώνει..'}

      {project && (
        <div className="bg-white rounded shadow p-3">
          {project && (
            <Tabs
              activeKey={type}
              onSelect={(tab) => navigate(`/projects/${projectId}/measurements/${tab}`)}
              className="mb-3"
              unmountOnExit
            >
              {Object.values(MEASUREMENT_CATEGORIES).map(key => (
                <Tab
                  eventKey={key}
                  key={key}
                  title={MEASUREMENT_CATEGORIES_LABELS[key]}
                >
                  <MeasurementVariationTab
                    measurementType={key}
                    project={project}
                  />
                </Tab>
              ))}
            </Tabs>
          )}
        </div>
      )}
    </DefaultLayout>
  );
}

export default ProjectView;
