import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import TransactionModalForm from './../components/forms/TransactionModalForm';
import { formatDate, formatMoneyAmount } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { createDS, updateDS } from '../dataServices/agreements';
import AsyncSelectInput from  './finalFormFields/AsyncSelectInput';
import SelectInput from  './finalFormFields/SelectInput';
import DataGrid from './DataGrid';
import { TRANSACTION_TYPES, TRANSACTION_TYPE_LABELS } from '../constants/const';

import {
  PencilIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';

const ProjectTransactionsTab = ({ project }) => {
  const model = `transactions/project/${project.id}`;
  const listExtras = useSelector((state) => state?.app?.lists?.[model]?.extra);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [modal, setModal] = useState({ show: false, model: null });

  const hideModal = () => {
    setModal({
      model: null,
      show: false,
      readOnly: false,
    });
  }

  const showModal = (model, readOnly = false) => {
    setModal({
      model,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('transactions', values.id, values));
    } else {
      dataService = dispatch(createDS('transactions', values));
    }

    return dataService
      .then(({ data, status }) => {
        if (status >= 400 ) {
          return data;
        } else {
          toastr.success('Έγινε αποθήκευση');
          hideModal();
          loadData();
        }
      });
  }

  const columns = [
    {
      name: 'Ποσό',
      render: (item) => (
        <a
          href="#"
          onClick={() => showModal(item, true)}
          className="list-item-link"
        >
          {formatMoneyAmount(item.amount)}
        </a>
      ),
    },
    {
      name: 'Καταχωρήθηκε',
      key: 'createdAt',
      render: (item) => formatDate(item.createdAt),
    },
    {
      name: 'Μέθοδος πληρωμής',
      render: (item) => TRANSACTION_TYPE_LABELS[item.method],
    },
    {
      name: 'Συνεργάτης / Πελάτης',
      key: 'contractor_supplier_client_id',
      render: (item) => {
        if (item?.clientId) {
          return (
            <Link to={`/clients/${item?.clientId}`}>
              {item?.client?.name}
            </Link>
          )
        } else if (item?.supplierId) {
          return (
            <Link to={`/suppliers/${item?.supplierId}`}>
             {item?.supplier?.name}
            </Link>
          )
        } if (item?.contractorId) {
          return (
            <Link to={`/contractors/${item?.contractorId}`}>
             {item?.contractor?.name}
            </Link>
          )
        } else {
          return '-';
        }

      },
    },
    // {
    //   name: 'Αρχείο',
    //   render: (item) => {
    //     if (!item.fileUrl) {
    //       return '-';
    //     }
    //
    //     const tooltip = (
    //       <Tooltip id="tooltip">
    //         Προβολή παραστατικού
    //       </Tooltip>
    //     );
    //
    //     return (
    //       <a
    //         target="_blank"
    //         href={`/api/${item.fileUrl}`}
    //       >
    //         <OverlayTrigger placement="top" overlay={tooltip}>
    //           <DocumentIcon width={25}/>
    //         </OverlayTrigger>
    //       </a>
    //     );
    //   }
    // },
    {
      name: '',
      render: (item) => (
        <div className="d-flex">
          <Button
            className="ms-auto"
            variant="outline-dark"
            onClick={() => showModal(item)}
            size="sm"
          >
            <PencilIcon className="ms-1" height={18} />
          </Button>
        </div>
      ),
    },
  ];

  if (!project) {
    return 'Φορτώνει..';
  }

  return (
    <>
      <DataGrid
        pushSearchButton={false}
        showSearch={false}
        filters={[
          {
            component: AsyncSelectInput,
            componentProps: {
              label: 'Πελάτης',
              name: 'clientId',
              searchUrl: 'clients',
              multiselect: true,
            },
          },
          {
            component: AsyncSelectInput,
            componentProps: {
              label: 'Εργολάβος',
              name: 'contractorId',
              searchUrl: 'contractors',
              multiselect: true,
            },
          },
          {
            component: AsyncSelectInput,
            componentProps: {
              label: 'Προμηθευτής',
              name: 'supplierId',
              searchUrl: 'suppliers',
              multiselect: true,
            },
          },
          {
            component: SelectInput,
            componentProps: {
              label: 'Μέθοδος πληρωμής',
              name: 'transactionMethod',
              options: Object.values(TRANSACTION_TYPES).map((type) => ({
                name: TRANSACTION_TYPE_LABELS[type],
                value: type,
              }))
            },
          }
        ]}
        loadData={loadData}
        model={model}
        searchContainerClass="shadow-none"
        tableContainerClass="shadow-none mt-0"
        striped
        hover
        columns={columns}
        showDeleteButton
      />


      <hr />
      {listExtras?.totalAmounts && (
        <>
          <div className="mb-2">
            Συναλλαγές προς συνεργάτες: <strong>{formatMoneyAmount(listExtras?.totalAmounts.totalTransactions)}</strong>
          </div>
          <div className="">
            Συναλλαγές από πελάτες: <strong>{formatMoneyAmount(listExtras?.totalAmounts.totalClientTransactions)}</strong>
          </div>
        </>
      )}

      <TransactionModalForm
        initialValues={{
          ...modal?.model,
        }}
        searchUrl={`agreements/project/${project.id}`}
        onHide={hideModal}
        show={modal.show}
        readOnly={modal.readOnly}
        onSubmit={submitForm}
      />
    </>
  );
}

export default ProjectTransactionsTab;
