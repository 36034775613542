import React, { useRef }  from  'react';
import { Form } from 'react-final-form';
import { Modal, Button } from 'react-bootstrap';

import { formatDate } from '../../utils/utils';
import { TRANSACTION_TYPES, TRANSACTION_TYPE_LABELS, DEFAULT_DATE_TIME_FORMAT } from '../../constants/const';
import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';
import AsyncSelectInput from  './../finalFormFields/AsyncSelectInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import SelectInput from  './../finalFormFields/SelectInput';
import DateSelectField from  './../finalFormFields/DateSelectField';
import FileInput from  './../finalFormFields/FileInput';

const TransactionModalForm = ({ searchUrl, readOnly, onHide, show, initialValues, onSubmit }) => {
  const isUpdate = initialValues?.id;
  const containerRef = useRef(null);
  let title = isUpdate ? 'Επεξεργασία' : 'Νέα πληρωμή';
  if (readOnly) {
    title = 'Πληροφορίες συναλλαγής';
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit} ref={containerRef}>
            <Modal
              enforceFocus
              container={containerRef}
              size="lg"
              backdrop="static"
              show={show}
              onHide={onHide}>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Field
                      component={TextInput}
                      prefix={<span>&euro;</span>}
                      label="Ποσό"
                      name="amount"
                      type="number"
                      step="0.01"
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      component={SelectInput}
                      label="Μέθοδος πληρωμής"
                      name="method"
                      type="text"
                      options={Object.values(TRANSACTION_TYPES).map((type) => ({
                        name: TRANSACTION_TYPE_LABELS[type],
                        value: type,
                      }))}
                      disabled={readOnly}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Field
                      type="date"
                      component={AsyncSelectInput}
                      label="Συμφωνία"
                      name="agreementId"
                      // searchUrl={`agreements/client/${initialValues?.clientId}`}
                      searchUrl={searchUrl}
                      getValueUrl={(value) => `agreements/${value}`}
                      required
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      type="date"
                      component={DateSelectField}
                      label="Ημερ/νία συναλλαγής"
                      name="date"
                      disabled={readOnly}
                      required
                    />
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Field
                      type="text"
                      component={TextInput}
                      label="Αριθμός παραστατικού"
                      name="documentNo"
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      type="date"
                      component={DateSelectField}
                      label="Ημερομηνία παραστατικού"
                      name="documentDate"
                      disabled={readOnly}
                    />
                  </div>
                </div>


                <Field
                  rows={2}
                  component={TextAreaInput}
                  label="Σχόλια"
                  name="comments"
                  disabled={readOnly}
                />


                <div className="row">
                  <div className="col-12">
                    <Field
                      component={FileInput}
                      label="Παραστατικό"
                      name="fileUrl"
                      disabled={readOnly}
                    />
                  </div>
                </div>

                {values?.id && (
                  <>
                    <div className="text-muted opacity-50 fs-6 mb-2 text-end">
                      Καταχωρήθηκε:
                      <span className="ms-1">
                        {formatDate(values.createdAt, DEFAULT_DATE_TIME_FORMAT)}
                      </span>
                    </div>

                    <div className="text-muted opacity-50 fs-6 text-end">
                      Τελευταία αλλαγή:
                      <span className="ms-1">
                        {formatDate(values.updatedAt, DEFAULT_DATE_TIME_FORMAT)}
                      </span>
                    </div>
                  </>
                )}

                {submitError && <div className="error">{submitError}</div>}
              </Modal.Body>
              <Modal.Footer>
                {readOnly && (
                  <Button variant="secondary" onClick={onHide}>
                    Κλείσιμο
                  </Button>
                )}
                {!readOnly && (
                  <>
                    <Button variant="secondary" onClick={onHide}>
                      Ακύρωση
                    </Button>
                    <Button variant="primary" type="submit">
                      {isUpdate ? 'Αποθήκευση' : 'Καταχώρηση'}
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
          </form>
        )}
    />
  );
};

export default TransactionModalForm;
