import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import DefaultLayout from './../layouts/DefaultLayout';
import SettingsUsersTab from './../components/SettingsUsersTab';
import SettingsLogsTab from './../components/SettingsLogsTab';

const SettingsView = () => {
  const [activeTab, setActiveTab] = useState('users');

  return (
    <DefaultLayout title="Διαχείριση">
      <div className="bg-white p-3">
        <Tabs
          activeKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)}
          className="mb-3"
          unmountOnExit
        >
          <Tab eventKey="users" title="Χρήστες">
            <SettingsUsersTab />
          </Tab>
          <Tab eventKey="logs" title="Ιστορικό αλλαγών">
            <SettingsLogsTab />
          </Tab>
        </Tabs>
      </div>
    </DefaultLayout>
  );
}

export default SettingsView;
