import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import toastr from 'toastr';
import axiosClient from '../utils/axios';

import DefaultLayout from './../layouts/DefaultLayout';
import { updateDS } from '../dataServices/model';
import ProjectForm from './../components/forms/ProjectForm';

const ProjectEditView = () => {
  const [project, setProject] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams()

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    const project = await axiosClient.get(`/projects/${projectId}`);
    setProject(project.data);
  };

  const onSubmit = async (values) => {
    return dispatch(updateDS('projects', project.id, values))
      .then(({ data }) => {
        if (data?._error) {
          toastr.error(data?._error);
          return data;
        } else {
          navigate('/projects');
          toastr.success('Έγινε αποθήκευση');
        }
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }


  return (
    <DefaultLayout title="Επεξεργασία Έργου">
      <div className="bg-white rounded shadow p-3 pb-4">
        {project && (
          <ProjectForm
            initialValues={{...project}}
            onSubmit={onSubmit}
            isCreate={false}
          />
        )}
      </div>
    </DefaultLayout>
  );
}

export default ProjectEditView;
