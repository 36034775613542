import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import { API_URL } from '../utils/axios';
import { TRANSACTION_TYPE_LABELS} from '../constants/const';
import { formatDate, formatMoneyAmount } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { createDS, updateDS } from '../dataServices/transactions';
import TransactionModalForm from './../components/forms/TransactionModalForm';
import DataGrid from './DataGrid';

import {
  PlusIcon,
  DocumentIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';

const ClientTransactionsTab = ({ client }) => {
  const model = `transactions/client/${client.id}`;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const listExtras = useSelector((state) => state?.app?.lists?.[model]?.extra);

  const [modal, setModal] = useState({ show: false, model: null });

  const hideModal = () => {
    setModal({
      model: null,
      show: false,
      readOnly: false,
    });
  }

  const showModal = (model, readOnly = false) => {
    setModal({
      model,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('transactions', values.id, values));
    } else {
      dataService = dispatch(createDS('transactions', values));
    }

    return dataService
      .then(({ data, status }) => {
        if (status >= 400 ) {
          return data;
        } else {
          toastr.success('Έγινε αποθήκευση');
          hideModal();
          loadData();
        }
      });
  }

  const columns = [
    {
      name: 'Ποσό',
      render: (item) => (
        <a
          href="#"
          onClick={() => showModal(item, true)}
          className="list-item-link"
        >
          {formatMoneyAmount(item.amount)}
        </a>
      ),
    },
    {
      name: 'Μέθοδος πληρωμής',
      render: (item) => TRANSACTION_TYPE_LABELS[item.method],
    },
    {
      name: 'Συμφωνία',
      render: (item) => item?.agreement?.title || '-',
    },
    {
      name: 'Παραστατικό',
      render: (item) => {
        if (!item.fileUrl) {
          return '-';
        }

        const tooltip = (
          <Tooltip id="tooltip">
            Προβολή παραστατικού
          </Tooltip>
        );

        return (
          <a
            target="_blank"
            href={`${API_URL}/${item.fileUrl}`}
          >
            <OverlayTrigger placement="top" overlay={tooltip}>
              <DocumentIcon width={25}/>
            </OverlayTrigger>
          </a>
        );
      }
    },
    {
      name: (<>Ημερ/νία<br />συναλλαγής</>),
      render: (item) => formatDate(item.date),
    },
    {
      name: '',
      render: (item) => (
        <div className="d-flex">
          <Button
            className="ms-auto"
            variant="outline-dark"
            onClick={() => showModal(item)}
            size="sm"
          >
            <PencilIcon className="ms-1" height={18} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="pt-3">
        Σύνολο πληρωμών: <strong>{formatMoneyAmount(listExtras?.totalAmount)}</strong>
      </div>

      <hr />

      <DataGrid
        showSearch={false}
        loadData={loadData}
        addItemComponent={(
          <button
            className="btn btn-lg btn-primary ms-auto"
            onClick={() => showModal({ amount: null })}
            type="button"
          >
            <PlusIcon />
            Νέα πληρωμή
          </button>
        )}
        model={model}
        searchContainerClass="shadow-none"
        tableContainerClass="shadow-none mt-0"
        striped
        hover
        columns={columns}
        showDeleteButton
      />

      <TransactionModalForm
        initialValues={{
          clientId: client.id,
          ...modal?.model,
        }}
        searchUrl={`agreements/client/${client.id}`}
        onHide={hideModal}
        show={modal.show}
        readOnly={modal.readOnly}
        onSubmit={submitForm}
      />
    </>
  );
}

export default ClientTransactionsTab;
