import axiosClient from '../utils/axios';
import {
  USER_FETCHED,
} from "../actionTypes/actionTypes";
import { parseDateValue } from '../utils/utils';

export const fetchCurrentUserDS = () => async (dispatch) => {
  await axiosClient
    .get(`/users/me`)
    .then(({ data }) => {
      dispatch({
        type: USER_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const createDS = (model, values, options) => async (dispatch) => {
  const formData = new FormData();
  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  if (values?.fileUrl?.[0]) {
    const arrayBuffer = await values.fileUrl[0].arrayBuffer();
    const blob = new Blob([new Uint8Array(arrayBuffer)], {type: values.fileUrl[0].type });
    formData.set('fileUrl', blob, values.fileUrl[0].name);
  }

  return await axiosClient
    .post(`/${model}/`, {
      ...values,
      date: parseDateValue(values?.date),
      documentDate: parseDateValue(values?.documentDate),
    })
    .then((response) => {
      return response;
    })
    .catch(function ({ response }) {
      return response;
    });
};

export const updateDS = (model, id, values, options) => async (dispatch) => {
  return await axiosClient
    .put(`/${model}/${id}`, {
      ...values,
      fileUrl: typeof values.fileUrl === 'string' ? undefined : values.fileUrl,
      date: parseDateValue(values?.date),
      documentDate: parseDateValue(values?.documentDate),
    })
    .then((response) => {
      return response;
    })
    .catch(function ({ response }) {
      return response;
    });
};
