import {
  USER_LOGIN,
  USER_FETCHED,
} from "../actionTypes/actionTypes.js";

const initialState = {
  auth: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        auth: action.payload,
      };
    case USER_FETCHED:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
