import {
  USER_LOGIN,
} from "../actionTypes/actionTypes";

export const userLogin = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};
