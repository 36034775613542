import React  from  'react';
import { Form } from 'react-final-form';
import Field from './../finalFormFields/Field';
import { Link } from "react-router-dom";

import TextInput from  './../finalFormFields/TextInput';

const LoginForm = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              component={TextInput}
              label="Email"
              name="email"
              type="email"
            />

            <Field
              component={TextInput}
              label="Password"
              name="password"
              type="password"
            />

            {submitError && <div className="error">{submitError}</div>}

            <div className="d-grid mt-4">
              <button
                className="btn btn-lg btn-primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Login
              </button>
            </div>

              {/*<div>*/}
              {/*  <p className="mb-0 mt-3  text-center">*/}
              {/*    {`Don't have an account? `}*/}
              {/*    <Link className="text-primary fw-bold" to="/signup">*/}
              {/*      Sign Up*/}
              {/*    </Link>*/}
              {/*  </p>*/}
              {/*</div>*/}
          </form>
        )}
    />

    // <Form
    //   onSubmit={onSubmit}
    //   initialValues={{stooge: 'larry', employed: false}}
    //   render={({handleSubmit, form, submitting, pristine, values}) => (
    //     <>
    //       <form onSubmit={handleSubmit} className="mb-3 mt-md-4">
    //         <h2 className="fw-bold mb-2 text-uppercase ">AIGrow</h2>
    //         <div className="mb-3">
    //           <Field name="email">
    //             {({ input, meta }) => (
    //               <div>
    //                 <label>Email</label>
    //                 <input
    //                   {...input}
    //                   type="email"
    //                   placeholder="Email"
    //                 />
    //                 {meta.error && meta.touched && <span>{meta.error}</span>}
    //               </div>
    //             )}
    //           </Field>
    //         </div>
    //         <div className="mb-1">
    //           <label htmlFor="password" className="form-label ">Password</label>
    //           <Field
    //             className="form-control"
    //             component="input"
    //             name="password"
    //             placeholder="Password"
    //             type="text"
    //           />
    //         </div>
    //
    //         <p className="small text-end">
    //           <Link className="text-primary" to="/forgot-password">
    //             Forgot password?
    //           </Link>
    //         </p>
    //
    //         <div className="d-grid">
    //           <button
    //             className="btn btn-lg btn-primary"
    //             type="submit"
    //             disabled={submitting || pristine}
    //           >
    //             Login
    //           </button>
    //         </div>
    //
    //         <div>
    //           <p className="mb-0 mt-3  text-center">
    //             {`Don't have an account? `}
    //             <Link className="text-primary fw-bold" to="/signup">Sign
    //               Up
    //             </Link>
    //           </p>
    //         </div>
    //       </form>
    //     </>
    //   )}
    // />
  );
};

export default LoginForm;
