import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import { Tab, Tabs, Card } from 'react-bootstrap';
import {
  ChevronRightIcon
} from '@heroicons/react/24/outline'

import DeleteModal from './../components/DeleteModal';
import axiosClient from '../utils/axios';
import DefaultLayout from './../layouts/DefaultLayout';
import ProjectVerticalPropertiesTab from './../components/ProjectVerticalPropertiesTab';
import ProjectTransactionsTab from './../components/ProjectTransactionsTab';
import ProjectAgreementsTab from './../components/ProjectAgreementsTab';
import ProjectFilesTab from './../components/ProjectFilesTab';
import ProjectMeasurementsTab from './../components/ProjectMeasurementsTab';

const ProjectView = () => {
  const [activeTab, setActiveTab] = useState('verticalProperties');
  const [project, setProject] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams()

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    const project = await axiosClient.get(`/projects/${projectId}`);
    setProject(project.data);
  };

  return (
    <DefaultLayout
      title={(
        <>
          <Link className="text-muted" to="/projects">Έργα</Link>
          <ChevronRightIcon height={18} className="mx-1" />
          {project?.title}
        </>
      )}
    >
      {!project && 'Φορτώνει..'}

      {project && (
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
            <Card
              className="border-0 shadow bg-white"
            >
              <Card.Header className="bg-white">
                <h3 className="my-2">Γενικά στοιχεία</h3>
              </Card.Header>

              <Card.Body
                className="details-list "
              >
              <div className="details-list-item">
                <div className="details-label">Τίτλος</div>
                <div className="details-value">{project.title}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Περιγραφή</div>
                <div className="details-value">
                  <p>
                    {project.description || '-'}
                  </p>
                </div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Σημειώσεις</div>
                <div className="details-value">
                  <p>
                    {project.notes || '-'}
                  </p>
                </div>
              </div>

                <hr />

                <Link
                  className="d-inline-flex w-auto btn btn-secondary btn-sm"
                  to={`/projects/${project.id}/edit`}
                >
                  Επεξεργασία στοιχείων
                </Link>

                <DeleteModal
                  title="Διαγραφή πελάτη"
                  model={project}
                  modelName="projects"
                  onDelete={() => navigate('/project')}
                />
              </Card.Body>
            </Card>
          </div>

          <div className="col-12 col-md-8 col-lg-9">
            <div className="bg-white rounded shadow p-3">
              {project && (
                <Tabs
                  activeKey={activeTab}
                  onSelect={(tab) => {
                    setSearchParams({});
                    setActiveTab(tab);
                  }}
                  className="mb-3"
                  unmountOnExit
                >
                  <Tab eventKey="verticalProperties" title="Κάθετες ιδιοκτησίες">
                    <ProjectVerticalPropertiesTab
                      onRefresh={() => fetchProject()}
                      project={project}
                    />
                  </Tab>

                  <Tab eventKey="agreements" title="Συμφωνίες">
                    <ProjectAgreementsTab
                      project={project}
                    />
                  </Tab>

                  <Tab eventKey="transactions" title="Συναλλαγές">
                    <ProjectTransactionsTab
                      project={project}
                    />
                  </Tab>

                  <Tab eventKey="measurement" title="Επιμετρήσεις">
                    <ProjectMeasurementsTab
                      project={project}
                    />
                  </Tab>

                  <Tab eventKey="files" title="Αρχεία">
                    <ProjectFilesTab
                      project={project}
                    />
                  </Tab>
                </Tabs>
              )}
            </div>
          </div>
        </div>
      )}
    </DefaultLayout>
  );
}

export default ProjectView;
