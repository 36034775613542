import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
  DocumentIcon,
} from '@heroicons/react/24/outline';

import { TRANSACTION_TYPE_LABELS } from '../constants/const';
import { formatDate, formatMoneyAmount } from '../utils/utils';
import DefaultLayout from './../layouts/DefaultLayout';
import DataGrid from './../components/DataGrid';

const columns = [
  {
    name: 'Ποσό',
    render: (item) => (
      <span className="fw-semibold">
        {formatMoneyAmount(item.amount)}
      </span>),
  },
  {
    name: 'Τύπος συναλλαγής',
    render: (item) => {
      if (item.contractorId) {
        return ('Πληρωμή εργολάβου');
      } else if (item.supplierId) {
        return ('Πληρωμή προμηθευτή');
      } else  if (item.clientId) {
        return ('Πληρωμή από πελάτη');
      }
    },
  },
  {
    name: 'Συνεργάτης / Πελάτης',
    render: (item) => {
      if (item.contractorId && item.contractor) {
        return (
          <Link to={`/contractors/${item.contractorId}`}>
            {item.contractor.name}
          </Link>
        )
      } else if (item.supplierId && item.supplier) {
        return (
          <Link to={`/suppliers/${item.supplierId}`}>
            {item.supplier.name}
          </Link>
        )
      } else  if (item.clientId && item.client) {
        return (
          <Link to={`/clients/${item.clientId}`}>
            {item.client.firstName} {item.client.lastName}
          </Link>
        )
      } else {
        return (<i className="text-muted opacity-50">Διαγραμμένος</i>);
      }
    },
  },
  {
    name: 'Έργο',
    render: (item) => item?.agreement?.project ? (
      <Link to={`/projects/${item?.agreement?.project?.id}`}>
        {item?.agreement?.project?.title}
      </Link>
    ) : '-',
  },
  {
    name: 'Συμφωνία',
    render: (item) => item?.agreement?.title || '-',
  },
  {
    name: 'Μέθοδος πληρωμής',
    render: (item) => TRANSACTION_TYPE_LABELS[item.method],
  },
  // {
  //   name: 'Παραστατικό',
  //   render: (item) => {
  //     if (!item.fileUrl) {
  //       return '-';
  //     }
  //
  //     const tooltip = (
  //       <Tooltip id="tooltip">
  //         Προβολή παραστατικού
  //       </Tooltip>
  //     );
  //
  //     return (
  //       <a
  //         target="_blank"
  //         href={`/api/${item.fileUrl}`}
  //       >
  //         <OverlayTrigger placement="top" overlay={tooltip}>
  //           <DocumentIcon width={25}/>
  //         </OverlayTrigger>
  //       </a>
  //     );
  //   }
  // },
  {
    name: 'Ημερ/νία συναλλαγής',
    render: (item) => formatDate(item.date),
  },
];

const TransactionsView = () => {
  return (
    <DefaultLayout
      title="Συναλλαγές"
      // cta={(
      //   <Link
      //     to="/transactions/create"
      //     className="btn btn-primary d-inline-block"
      //     type="button"
      //   >
      //     <PlusIcon />
      //     Νέα συναλλαγή
      //   </Link>
      // )}
    >
      <DataGrid
        showSearch={false}
        model="transactions"
        striped
        hover
        responsive
        // showDeleteButton
        columns={columns}
      />
    </DefaultLayout>
  );
}

export default TransactionsView;
