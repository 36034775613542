import React, { useState } from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import { format as dateFnsFormat, parseISO } from 'date-fns';


import { formatDate } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { updateDS, createDS } from '../dataServices/model';
import MeasurementModalForm from './../components/forms/MeasurementModalForm';
import DataGrid from './DataGrid';
import { useParams } from 'react-router-dom';
import {
  MEASUREMENT_CATEGORY_VARIATIONS,
  MEASUREMENT_CATEGORY_VARIATIONS_LABELS,
} from '../constants/const';
import {
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
import { DEFAULT_DATE_TIME_FORMAT } from '../constants/const';

const MeasurementVariationTab = ({ project, measurementType, onRefresh }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { type, projectId } = useParams();
  const initialVariation = MEASUREMENT_CATEGORY_VARIATIONS[type] && Object.values(MEASUREMENT_CATEGORY_VARIATIONS[type])[0];
  const [selectedVariation, setSelectedVariation] = useState(initialVariation);
  const [measurementModal, setMeasurementModal] = useState({ show: false, measurement: null });
  const hideMeasurementModal = () => {
    setMeasurementModal({
      measurement: null,
      show: false,
      readOnly: false,
    });
  }
  const model = `measurements/project/${project.id}/${measurementType}/${selectedVariation}`;

  const showMeasurementModal = (measurement, readOnly = false) => {
    const _measurement = {
      ...measurement,
      deliveredAt: measurement.deliveredAt
        ? dateFnsFormat(parseISO(measurement.deliveredAt), 'yyyy-MM-dd')
        : undefined,
    }
    setMeasurementModal({
      measurement: _measurement,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('measurements', values.id, values));
    } else {
      dataService = dispatch(createDS('measurements', values));
    }

    return dataService.then(async ({ data }) => {
      if (data?._error) {
        toastr.error(data?._error);
        return data;
      } else {
        hideMeasurementModal();
        loadData();
        toastr.success('Έγινε αποθήκευση');
      }
    })
    .catch(err => {
      console.log('ERROR #', err);
    });
  }

  const columns = [
    {
      name: 'Στάθμη',
      render: (item) => item.level || '-',
    },
    {
      name: 'Θέση',
      render: (item) => item.position || '-',
    },
    {
      name: 'Μήκος',
      render: (item) => item.length ? `${item.length} m` : '-',
    },
    {
      name: 'Ύψος',
      render: (item) => item.height ? `${item.height} m` : '-',
    },
    {
      name: 'Βάθος',
      render: (item) => item.depth ? `${item.depth} m` : '-',
    },
    {
      name: 'Πολλαπλασιαστής',
      render: (item) => item.multiplier || '-',
    },
    {
      name: 'Σημειώσεις',
      render: (item) => item.comments || '-',
    },
    {
      name: 'Ημερ/νία Παραλαβής',
      render: (item) => formatDate(item.deliveredAt, DEFAULT_DATE_TIME_FORMAT),
    },
    // {
    //   name: 'Εμβαδόν (μικτό)',
    //   render: (item) => item.grossArea
    //     ? (
    //       <span>
    //         {item.grossArea} m<sup>2</sup>
    //       </span>
    //     )
    //     : '-',
    // },
    // {
    //   name: 'Εμβαδόν (καθαρό)',
    //   render: (item) => item.netArea
    //     ? (
    //       <span>
    //         {item.netArea} m<sup>2</sup>
    //       </span>
    //     )
    //     : '-',
    // },
    {
      name: '',
      render: (item) => (
        <div className="d-flex">
          <Button
            className="ms-auto"
            variant="outline-dark"
            onClick={() => showMeasurementModal(item)}
            size="sm"
          >
            <PencilIcon className="ms-1" height={18} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Tabs
        className="mb-3 tabs-simple"

        activeKey={selectedVariation}
        onSelect={(newVariation) => setSelectedVariation(newVariation)}
      >
        {MEASUREMENT_CATEGORY_VARIATIONS[type] && Object.values(MEASUREMENT_CATEGORY_VARIATIONS[type]).map(variation => (
          <Tab
            key={`tab-${type}-${variation}`}
            unmountOnExit
            eventKey={variation}
            title={MEASUREMENT_CATEGORY_VARIATIONS_LABELS[type][variation]}
          >
            <DataGrid
              loadData={loadData}
              showDeleteButton
              showSearch={false}
              addItemComponent={(
                <button
                  className="btn btn-lg btn-primary ms-auto"
                  onClick={() => showMeasurementModal({
                    projectId,
                    position: '',
                    quantity: 1,
                    multiplier: 1,
                    measurementType: type,
                    measurementVariation: variation,
                  })}
                  type="button"
                >
                  <PlusIcon />
                  Νέα καταχώρηση
                </button>
              )}
              model={model}
              tableContainerClass="shadow-none"
              striped
              hover
              columns={columns}
            />
          </Tab>
        ))}
      </Tabs>

      <MeasurementModalForm
        initialValues={{
          projectId: project.id,
          ...measurementModal?.measurement,
        }}
        onHide={hideMeasurementModal}
        show={measurementModal.show}
        readOnly={measurementModal.readOnly}
        onSubmit={submitForm}
      />
    </>
  );
}

export default MeasurementVariationTab;
