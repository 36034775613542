import React  from  'react';
import { Form } from 'react-final-form';
import Field from './../finalFormFields/Field';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DateSelectField from  './../finalFormFields/DateSelectField';
import SelectInput from  './../finalFormFields/SelectInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import TextInput from  './../finalFormFields/TextInput';

const ClientForm = ({
  isCreate = true,
  onSubmit,
  initialValues,
}) => {
  const navigate = useNavigate();



  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={
        ({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Field
                    component={TextInput}
                    label="Όνομα"
                    name="firstName"
                    type="text"
                    required
                  />
                </div>

                <div className="col-12 col-md-6">
                  <Field
                    component={TextInput}
                    label="Επώνυμο"
                    name="lastName"
                    type="text"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <Field
                    component={TextInput}
                    label="Τηλέφωνο"
                    name="phone"
                    type="text"
                    required
                  />
                </div>

                <div className="col-12 col-md-6">
                  <Field
                    component={TextInput}
                    label="Email"
                    name="email"
                    type="text"
                    required
                  />
                </div>

                <div className="col-12 col-md-6">
                </div>
              </div>

              <Field
                rows={1}
                component={TextAreaInput}
                label="Δυέυθυνση"
                name="address"
              />

              <Field
                rows={5}
                component={TextAreaInput}
                label="Σχόλια"
                name="notes"
              />

              {/*<Field*/}
              {/*  component={SelectInput}*/}
              {/*  label="Type"*/}
              {/*  name="type"*/}
              {/*  options={[*/}
              {/*    { name: 'a', value: '1' },*/}
              {/*    { name: 'n', value: '2' },*/}
              {/*  ]}*/}
              {/*/>*/}

              {submitError && <div className="error">{submitError}</div>}

              <div className="mt-4 d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-secondary"
                  onClick={() => navigate(-1)}
                  type="button"
                  disabled={submitting}
                >
                  Ακύρωση
                </button>

                <button
                  className="btn btn-lg btn-primary"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  {isCreate ? 'Δημιουργία' : 'Αποθήκευση'}
                </button>
              </div>
            </form>
          );
        }}
    />
  );
};

export default ClientForm;
