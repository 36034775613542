import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import { Tab, Tabs, Card } from 'react-bootstrap';
import {
  ChevronRightIcon
} from '@heroicons/react/24/outline';

import axiosClient from '../utils/axios';
import DeleteModal from './../components/DeleteModal';
import DefaultLayout from './../layouts/DefaultLayout';
import SupplierTransactionsTab from './../components/SupplierTransactionsTab';
import SupplierAgreementsTab from './../components/SupplierAgreementsTab';

const SupplierView = () => {
  const [activeTab, setActiveTab] = useState('agreements');
  const [model, setModel] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { supplierId } = useParams();

  useEffect(() => {
    fetchModel();
  }, []);

  const fetchModel = async () => {
    const model = await axiosClient.get(`/suppliers/${supplierId}`);
    setModel(model.data);
  };

  return (
    <DefaultLayout
      title={(
        <>
          <Link to="/suppliers" className="text-muted">
            Προμηυευτές
          </Link>
          <ChevronRightIcon height={18} className="mx-1" />
          {model?.name}
        </>
      )}
    >
      <div className="row">
        <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
          <Card
            className="border-0 shadow bg-white"
          >
            <Card.Header className="bg-white">
              <h3 className="my-2">Στοιχεία επικοινωνίας</h3>
            </Card.Header>
            <Card.Body
              className="details-list "
            >
              <div className="details-list-item">
                <div className="details-label">Όνομα</div>
                <div className="details-value">{model?.name || '-'}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Τηλεφωνο</div>
                <div className="details-value">{model?.phone || '-'}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Email</div>
                <div className="details-value">{model?.email || '-'}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Περιγραφή</div>
                <div className="details-value">
                  <p>
                    {model?.description || '-'}
                  </p>
                </div>
              </div>

              <hr />

              <Link
                className="ms-auto d-inline-flex w-auto btn btn-secondary btn-sm"
                to={`/suppliers/${model?.id}/edit`}
              >
                Επεξεργασία στοιχείων
              </Link>

              <DeleteModal
                title="Διαγραφή προμηθευτή"
                model={model}
                modelName="suppliers"
                onDelete={() => navigate('/suppliers')}
              />
            </Card.Body>
          </Card>
        </div>

        <div className="col-12 col-md-8 col-lg-9">
          <div className="bg-white rounded shadow p-3">
            {model && (
              <Tabs
                activeKey={activeTab}
                onSelect={(tab) => {
                  setSearchParams({});
                  setActiveTab(tab);
                }}
                className="mb-3"
                unmountOnExit
              >
                <Tab eventKey="agreements" title="Συμφωνίες">
                  <SupplierAgreementsTab
                    supplier={model}
                  />
                </Tab>

                <Tab eventKey="payments" title="Πληρωμές">
                  <SupplierTransactionsTab supplier={model} />
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default SupplierView;
