import React from 'react';

import DefaultLayout from './../layouts/DefaultLayout';
import DataGrid from './../components/DataGrid';

const UsersView = () => {
  return (
    <DefaultLayout title="Χρήστες">
      .
    </DefaultLayout>
  );
}

export default UsersView;
