import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import { scrollToSelector } from '../utils/utils';
import { formatDate, formatMoneyAmount } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { createDS, updateDS } from '../dataServices/users';
import UserModalForm from './../components/forms/UserModalForm';
import DataGrid from './DataGrid';

import {
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'

const SettingsUsersTab = () => {
  const model = 'users';

  const dispatch = useDispatch();
  const { search } = useLocation();
  const listExtras = useSelector((state) => state?.app?.lists?.[model]?.extra);

  const [modal, setModal] = useState({ show: false, model: null });

  const hideModal = () => {
    setModal({
      model: null,
      show: false,
      readOnly: false,
    });
  }

  const showModal = (model, readOnly = false) => {
    setModal({
      model,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      scrollToSelector('.table-container');
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('users', values.id, values));
    } else {
      dataService = dispatch(createDS('users', values));
    }

    return dataService
      .then(({ data, status }) => {
        if (status >= 400 ) {
          return data;
        } else {
          toastr.success('Έγινε αποθήκευση');
          hideModal();
          loadData();
        }
      });
  }

  const columns = [
    {
      name: 'Ονοματεπώνυμο',
      render: (item) => (
        <a
          href="#"
          onClick={() => showModal(item, true)}
          className="list-item-link"
        >
          {item.firstName} {item.lastName}
        </a>
      ),
    },
    {
      name: 'Email',
      render: (item) => item.email,
    },
    {
      name: '',
      render: (item) => (
        <div className="d-flex">
          <Button
            className="ms-auto"
            variant="outline-dark"
            onClick={() => showModal(item)}
            size="sm"
          >
            <PencilIcon className="ms-1" height={18} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        showSearch={false}
        loadData={loadData}
        addItemComponent={(
          <button
            className="btn btn-lg btn-primary ms-auto"
            onClick={() => showModal({ firstName: null })}
            type="button"
          >
            <PlusIcon />
            Νέας χρήστης
          </button>
        )}
        model={model}
        searchContainerClass="shadow-none"
        tableContainerClass="shadow-none"
        striped
        hover
        columns={columns}
        showDeleteButton
      />

      { modal.show && (
        <UserModalForm
          initialValues={{ ...modal?.model }}
          searchUrl="users"
          onHide={hideModal}
          show={modal.show}
          readOnly={modal.readOnly}
          onSubmit={submitForm}
        />
      )}
    </>
  );
}

export default SettingsUsersTab;

