import React, { useRef }  from  'react';
import { Form } from 'react-final-form';
import { Modal, Button } from 'react-bootstrap';

import {
  VERTICAL_PROPERTY_TYPES,
  VERTICAL_PROPERTY_TYPE_LABELS,
  MEASUREMENT_CATEGORY_VARIATIONS,
  MEASUREMENT_CATEGORY_VARIATIONS_LABELS,
} from '../../constants/const';
import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import DateSelectField from  './../finalFormFields/DateSelectField';
import SelectInput from  './../finalFormFields/SelectInput';

const MeasurementModalForm = ({ readOnly, onHide, show, initialValues, onSubmit }) => {
  const isUpdate = initialValues?.id;
  const containerRef = useRef(null);
  let title = isUpdate ? 'Επεξεργασία' : 'Νέα καταχώρηση';
  if (readOnly) {
    title = 'Κάθετη ιδιοκτησία';
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit} ref={containerRef}>
            <Modal
              enforceFocus
              container={containerRef}
              size="lg"
              backdrop="static"
              show={show}
              onHide={onHide}>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Field
                      component={TextInput}
                      label="Στάθμη"
                      name="level"
                      type="text"
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      component={TextInput}
                      label="Θέση"
                      name="position"
                      type="text"
                      disabled={readOnly}
                    />
                    {/*<Field*/}
                    {/*  component={SelectInput}*/}
                    {/*  label="position"*/}
                    {/*  name="Θέση"*/}
                    {/*  type="text"*/}
                    {/*  options={Object.values(VERTICAL_PROPERTY_TYPES).map((type) => ({*/}
                    {/*    name: VERTICAL_PROPERTY_TYPE_LABELS[type],*/}
                    {/*    value: type,*/}
                    {/*  }))}*/}
                    {/*  disabled={readOnly}*/}
                    {/*/>*/}
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      suffix="m"
                      component={TextInput}
                      label="Μήκος (m)"
                      name="length"
                      type="number"
                      step="0.01"
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      suffix="m"
                      component={TextInput}
                      label="Ύψος (m)"
                      name="height"
                      type="number"
                      step="0.01"
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      suffix="m"
                      component={TextInput}
                      label="Βάθος (m)"
                      name="depth"
                      type="number"
                      step="0.01"
                      disabled={readOnly}
                    />
                  </div>
                </div>
                <div className="row">

                  <div className="col-12 col-lg-6">
                    <Field
                      component={TextInput}
                      label="Πολλαπλασιαστής"
                      name="multiplier"
                      type="number"
                      step="0.01"
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12 col-lg-6">
                    <Field
                      type="date"
                      component={DateSelectField}
                      label="Ημερ/νία Παραλαβής"
                      name="deliveredAt"
                      disabled={readOnly}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Field
                      rows={2}
                      component={TextAreaInput}
                      label="Περιγραφή"
                      name="comments"
                      disabled={readOnly}
                    />
                  </div>
                </div>

                {submitError && <div className="error">{submitError}</div>}
              </Modal.Body>
              <Modal.Footer>
                {readOnly && (
                  <Button variant="secondary" onClick={onHide}>
                    Κλείσιμο
                  </Button>
                )}
                {!readOnly && (
                  <>
                    <Button variant="secondary" onClick={onHide}>
                      Ακύρωση
                    </Button>
                    <Button variant="primary" type="submit">
                      {isUpdate ? 'Αποθήκευση' : 'Προσθήκη'}
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
          </form>
        )}
    />
  );
};

export default MeasurementModalForm;
