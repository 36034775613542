import React from 'react';

import DefaultLayout from './../layouts/DefaultLayout';

const NotFoundView = () => {
  return (
    <DefaultLayout>
      Η σελίδα δεν βρέθηκε.
    </DefaultLayout>
  );
}

export default NotFoundView;
