import React, { useEffect, useState } from  'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { fetchCurrentUserDS } from '../dataServices/users';
import {

} from '../dataServices/global';

const RequireAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    const authData = localStorage.getItem('auth');
    if (authData) {
      dispatch(fetchCurrentUserDS()).then(() => {
        setIsAuthed(true);
      });
      fetchGlobalData();
    } else {
      navigate('/login');
    }
  }, []);

  const fetchGlobalData = () => {
    // dispatch(fetchCropTypes());
  };

  return (
    <>
      {isAuthed && <Outlet />}
    </>
  )
};

export default RequireAuth;
