const TextAreaInput = ({
  label,
  className,
  type,
  input: {
    name,
      ...rest
  },
  rows,
  options,
  input,
  meta: {
    touched,
    error,
    submitError,
  },
  disabled,
  meta,
}) => (
  <div className={`mb-3 ${className}`}>
    <label
      htmlFor={name}
      className="form-label"
    >
      {label}
    </label>

    <textarea
      name={name}
      disabled={disabled}
      className="form-control"
      rows={rows}
      {...rest}
    >

    </textarea>

    {(error || submitError) && touched && (
      <div className="text-danger my-1">{error || submitError}</div>
    )}
  </div>
);

export default TextAreaInput;
