import React from 'react';
import { Routes, Route } from "react-router-dom";

import AuthLayout from './layouts/AuthLayout';

import ClientCreateView from './views/ClientCreateView';
import ClientEditView from './views/ClientEditView';
import ClientView from './views/ClientView';
import ClientsView from './views/ClientsView';

import ContractorCreateView from './views/ContractorCreateView';
import ContractorEditView from './views/ContractorEditView';
import ContractorView from './views/ContractorView';
import ContractorsView from './views/ContractorsView';

import LoginView from './views/LoginView';
import NotFoundView from './views/NotFoundView';

import ProjectsView from './views/ProjectsView';
import ProjectView from './views/ProjectView';
import ProjectCreateView from './views/ProjectCreateView';
import ProjectEditView from './views/ProjectEditView';
import ProjectMeasurementsView from './views/ProjectMeasurementsView';

import RequireAuth from './components/RequireAuth';

import SuppliersView from './views/SuppliersView';
import SupplierCreateView from './views/SupplierCreateView';
import SupplierView from './views/SupplierView';
import SupplierEditView from './views/SupplierEditView';

import TransactionsView from './views/TransactionsView';

import UsersView from './views/UsersView';

import SettingsView from './views/SettingsView';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth />}>
        <Route path="/" element={<ProjectsView />} />

        <Route path="/clients/" element={<ClientsView />} />
        <Route path="/clients/create" element={<ClientCreateView />} />
        <Route path="/clients/:clientId" element={<ClientView />} />
        <Route path="/clients/:clientId/edit" element={<ClientEditView />} />

        <Route path="/suppliers" element={<SuppliersView />} />
        <Route path="/suppliers/create" element={<SupplierCreateView />} />
        <Route path="/suppliers/:supplierId" element={<SupplierView />} />
        <Route path="/suppliers/:supplierId/edit" element={<SupplierEditView />} />

        <Route path="/projects" element={<ProjectsView />} />
        <Route path="/projects/create" element={<ProjectCreateView />} />
        <Route path="/projects/:projectId" element={<ProjectView />} />
        <Route path="/projects/:projectId/edit" element={<ProjectEditView />} />
        <Route path="/projects/:projectId/measurements/:type" element={<ProjectMeasurementsView />} />

        <Route path="/users" element={<UsersView />} />

        <Route path="/transactions" element={<TransactionsView />} />

        <Route path="/contractors" element={<ContractorsView />} />
        <Route path="/contractors/create" element={<ContractorCreateView />} />
        <Route path="/contractors/:contractorId" element={<ContractorView />} />
        <Route path="/contractors/:contractorId/edit" element={<ContractorEditView />} />

        <Route path="/settings" element={<SettingsView />} />

        <Route path="*" element={<NotFoundView />} />
      </Route>

      <Route path="/" element={<AuthLayout />}>
        <Route path="login" element={<LoginView />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
