import React, { useState } from  'react';
import { NavLink, Link } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import {
  XMarkIcon,
  Bars3Icon,
} from '@heroicons/react/24/outline'
import {
  UsersIcon,
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
  Cog6ToothIcon,
  ShoppingCartIcon,
  CurrencyEuroIcon,
} from '@heroicons/react/24/outline'

// import logo from '../assests/img/logo.png';


const DefaultLayout = ({ cta, title, children }) => {
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const userLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('auth');
    navigate('/login');
  }
  const userData = useSelector((state) => state?.auth?.user);
  const navbarClassNames = ['navbar-col'];

  if (menuIsOpen) {
    navbarClassNames.push('open');
  }

  return (
    <div className={`layout--default ${menuIsOpen ? 'menu-open' : ''}`}>
      <div className={navbarClassNames.join(' ')}>
        <img height="110" className="logo mt-3" alt="logo" src="/logo.png" />

        <div
          onClick={() => setMenuIsOpen(false)}
          className="navbar-toggle"
        >
          <XMarkIcon />
        </div>

        <nav className="main-nav flex-column mt-3">
          <ul className="nav-list">
            <li>
              <NavLink to="/projects">
                <div className="icon"><BuildingOffice2Icon /></div>
                Έργα
              </NavLink>
            </li>

            <li>
              <NavLink to="/clients">
                <div className="icon"><UsersIcon /></div>
                Πελάτες
              </NavLink>
            </li>

            <li>
              <NavLink to="/suppliers">
                <div className="icon"><ShoppingCartIcon /></div>
                Προμηθευτές
              </NavLink>
            </li>

            <li>
              <NavLink to="/contractors">
                <div className="icon"><Icon icon="mdi:construction-outline" /></div>
                Εργολάβοι
              </NavLink>
            </li>

            <li>
              <NavLink to="/transactions">
                <div className="icon"><CurrencyEuroIcon /></div>
                Συναλλαγές
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="navbar__footer">
          <small className="text-muted ps-4 mb-3">
            {`${userData?.firstName} ${userData?.lastName}`}
          </small>

          <nav>
            <ul className="nav-list">
              <li>

                <NavLink to="/settings">
                  <div className="icon"><Cog6ToothIcon /></div>
                  Διαχείριση
                </NavLink>
              </li>

              <li>
                <a href="#" onClick={userLogOut}>
                  <div className="icon"><ArrowRightOnRectangleIcon /></div>
                  Αποσύνδεση
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="content-col">
        <div className="content-header__wrapper">
          <div className="content-header">
            <div
              onClick={() => setMenuIsOpen(true)}
              className="open-navbar-toggle d-block d-md-none"
            >
              <Bars3Icon />
            </div>

            <div className="container d-flex align-items-center justify-content-between">
              { title && (
                <h1>{title}</h1>
              )}

              { cta }
            </div>
          </div>
        </div>

        <div className="page">
          <div className="container">
            <Outlet />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
