import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr'

import { formatDate, formatMoneyAmount } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { createDS, updateDS } from '../dataServices/agreements';
import MeasurementModalForm from './../components/forms/MeasurementModalForm';
import DataGrid from './DataGrid';
import { MEASUREMENT_CATEGORIES, MEASUREMENT_CATEGORIES_LABELS } from '../constants/const';

import {
  PencilIcon,
} from '@heroicons/react/24/outline';

const ProjectMeasurementsTab = ({ project }) => {
  const model = `projects/${project.id}/measurements`;
  const listExtras = useSelector((state) => state?.app?.lists?.[model]?.extra);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [modal, setModal] = useState({ show: false, model: null });

  const hideModal = () => {
    setModal({
      model: null,
      show: false,
      readOnly: false,
    });
  }

  const showModal = (model, readOnly = false) => {
    setModal({
      model,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('measurements', values.id, values));
    } else {
      dataService = dispatch(createDS('measurements', values));
    }

    return dataService
      .then(({ data, status }) => {
        if (status >= 400 ) {
          return data;
        } else {
          toastr.success('Έγινε αποθήκευση');
          hideModal();
          loadData();
        }
      });
  }

  if (!project) {
    return 'Φορτώνει..';
  }

  return (
    <div>
      {Object.values(MEASUREMENT_CATEGORIES).map(key => (
        <Link
          key={key}
          className="btn btn-outline-primary"
          to={`/projects/${project.id}/measurements/${key}`}
        >
          {MEASUREMENT_CATEGORIES_LABELS[key]}
        </Link>
      ))}
    </div>
  );
}

export default ProjectMeasurementsTab;
