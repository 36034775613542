import React, { useEffect, useState } from 'react';
import {
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { scrollToSelector } from '../utils/utils';
import DataGridSearch from './DataGridSearch';
import DataGridPagination from './DataGridPagination';
import { fetchListDS, deleteDS } from '../dataServices/model';

const DataGrid = ({
  showSearch = true,
  showDeleteButton = false,
  dataLoader = null,
  addItemComponent,
  bordered = false,
  containerClass,
  hover = false,
  model,
  responsive = true,
  striped = false,
  filters = [],
  pushSearchButton = true,
  columns,
  searchContainerClass = 'p-3 mb-4',
  tableContainerClass = 'p-3',
}) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState({ show: false, model: null });

  const showDeleteModal = (model) => {
    setDeleteModal({
      model,
      show: true,
    });
  };

  const hideDeleteModal = () => {
    setDeleteModal({
      model: null,
      show: false,
    });
  };

  const deleteItem = (item) => {
    return dispatch(deleteDS(model, item.id)).then(() => {
      scrollToSelector('.table-container');
    }).then(() => {
      loadData();
      hideDeleteModal();
    });
  };

  const items = useSelector((state) => state?.app?.lists?.[model]?.data);
  const pagination = useSelector((state) => state?.app?.lists?.[model]?.pagination);

  useEffect(() => {
    loadData();
  }, [search]);

  const loadData = () => {
    if (dataLoader) {
      dataLoader().then(() => {
        setIsLoading(false);
      });
    } else {
      dispatch(fetchListDS(model, search)).then(() => {
        scrollToSelector('.table-container');
      }).then(() => {
        setIsLoading(false);
      });
    }
  };

  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );

  const _containerClass = [
    containerClass,
  ];
  const _tableContainerClass = [
    'table-container bg-white rounded shadow',
    tableContainerClass,
  ];

  const tableClass = [
    'table mb-0',
  ];

  if (striped) {
    tableClass.push('table-custom-striped');
  }
  if (hover) {
    tableClass.push('table-custom-hover');
  }
  if (bordered) {
    tableClass.push('table-bordered');
  }
  if (responsive) {
   //  _containerClass.push('table-responsive');
  }

  return (
    <div className={_containerClass.join(' ')}>
      {addItemComponent && (
        <div className="d-flex mt-3">
          {addItemComponent}
        </div>
      )}

      <DataGridSearch
        showSearch={showSearch}
        filters={filters}
        className={searchContainerClass}
        initialValues={searchAsObject}
        pushSearchButton={pushSearchButton}
        onSubmit={(values => {
          setSearchParams({
            ...searchAsObject,
            ...values,
            q: values.q || '',
            page: 1,
          });
        })}
      />

      {items?.length === 0 && (
        <div className="p-4 text-center">
          <i>Δεν βρέθηκαν εγγραφές</i>
        </div>
      )}

      {items?.length > 0 && (
        <>
          <div className={_tableContainerClass.join(' ')}>
            <table className={tableClass.join(' ')}>
              <thead>
                <tr>
                  {columns.map(({ key, name }) => (
                    <th key={key || name} scope="col">{name}</th>
                  ))}
                  <th />
                </tr>
              </thead>

              <tbody>
                {items && items.map((item, rowIndex) => (
                  <tr key={item.id}>
                    <>
                      {columns.map(({ name, key, render }) => (
                        <td data-title={name || ''} key={`td-${key || name}-${item.id}`} scope="col">
                          <div>{render(item, rowIndex)}</div>
                        </td>
                      ))}
                      <td style={{ width: '50px' }} className="text-end">
                        {showDeleteButton && (
                          <Button
                            className="ms-auto"
                            variant="outline-danger"
                            onClick={() => showDeleteModal(item)}
                            size="sm"
                          >
                            <TrashIcon className="ms-1" height={18} />
                          </Button>
                        )}
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            {pagination && (
              <DataGridPagination
                page={pagination?.currentPage}
                total={pagination?.totalCount}
                limit={pagination?.pageSize}
                changePage={newPage => {
                  setSearchParams({
                    ...searchAsObject,
                    page: newPage,
                  });
                }}
              />
            )}
          </div>


          <Modal
            enforceFocus
            backdrop="static"
            show={deleteModal.show}
            onHide={hideDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Επιβεβαίωση διαγραφής</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              Είστε βέβαιος πως θέλετε να γίνει διαγραφή;
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={hideDeleteModal}>
                  Ακύρωση
                </Button>
                <Button variant="danger" onClick={() => deleteItem(deleteModal.model)}>
                  Διαγραφή
                </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
}

export default DataGrid;
