import React, { useState } from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const DataGridPagination = (props) => {

  return (
    <div className="d-flex flex-column-reverse flex-md-row justify-content-between align-items-center">
      <div className="mt-3 mt-md-0">
        <div style={{ width: 'fit-content' }}>
          <PaginationControl
            between={3}
            ellipsis={1}
            // limit={20}
            // total={250}
            // page={page}
            // changePage={onPageChange}
            { ...props }
          />
        </div>
      </div>

      <div className="text-muted">
        {props?.total === 1 ? `Βρέθηκε ${props?.total} αποτέλεσμα` : `Βρέθηκαν ${props?.total} αποτελέσματα`}
      </div>
    </div>
  );
}

export default DataGridPagination;
