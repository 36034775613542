import React from 'react';
import { Link } from "react-router-dom";
import {
  PlusIcon,
} from '@heroicons/react/24/outline'

import { formatDate } from '../utils/utils';
import DefaultLayout from './../layouts/DefaultLayout';
import DataGrid from './../components/DataGrid';

const columns = [
  {
    name: 'Τίτλος έργου',
    render: (item) => (
      <Link className="list-item-link" to={`/projects/${item.id}`}>
        {item.title}
      </Link>
    ),
  },
  {
    name: 'Ημερ/νία δημιουργίας',
    render: (item) => formatDate(item.createdAt),
  },
];

const ProjectsView = () => {
  return (
    <DefaultLayout
      title="Έργα"
      cta={(
        <Link
          to="/projects/create"
          className="btn btn-primary d-inline-block"
          type="button"
        >
          <PlusIcon />
          Νέο έργο
        </Link>
      )}
    >
      <DataGrid
        model="projects"
        striped
        hover
        responsive
        columns={columns}
      />
    </DefaultLayout>
  );
}

export default ProjectsView;
