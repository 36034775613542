import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from 'toastr';

import DefaultLayout from './../layouts/DefaultLayout';
import { createDS } from '../dataServices/model';
import ProjectForm from './../components/forms/ProjectForm';

const ProjectCreateView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    return dispatch(createDS('projects', values))
      .then(({ data }) => {
        if (data?._error) {
          toastr.error(data?._error);
          return data;
        } else {
          navigate('/projects');
          toastr.success('Το έργο δημιουργήθηκε με επιτυχία');
        }
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <DefaultLayout title="Νέο έργο">
      <div className="bg-white rounded shadow p-3 pb-4">
        <ProjectForm
          onSubmit={onSubmit}
        />
      </div>
    </DefaultLayout>
  );
}

export default ProjectCreateView;
