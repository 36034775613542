import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';

import { fetchListDS } from '../dataServices/model';
import { updateDS, createDS } from '../dataServices/model';
import VerticalPropertyModalForm from './../components/forms/VerticalPropertyModalForm';
import DataGrid from './DataGrid';
import { VERTICAL_PROPERTY_TYPE_LABELS } from '../constants/const';
import {
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'

const ProjectVerticalPropertiesTab = ({ project, onRefresh }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const model = `projects/${project.id}/vertical-properties`;
  const [propertyModal, setPropertyModal] = useState({ show: false, property: null });
  const hidePropertyModal = () => {
    setPropertyModal({
      property: null,
      show: false,
      readOnly: false,
    });
  }

  const showPropertyModal = (property, readOnly = false) => {
    setPropertyModal({
      property,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('vertical-properties', values.id, values));
    } else {
      dataService = dispatch(createDS('vertical-properties', values));
    }

    return dataService.then(async ({ data }) => {
      if (data?._error) {
        toastr.error(data?._error);
        return data;
      } else {
        hidePropertyModal();
        loadData();
        toastr.success('Έγινε αποθήκευση');
      }
    })
    .catch(err => {
      console.log('ERROR #', err);
    });
  }

  const columns = [
    {
      name: 'Όνομα',
      render: (item) => (
        <a
          href="#"
          onClick={() => showPropertyModal(item, true)}
          className="list-item-link"
        >
          {item.title}
        </a>
      ),
    },
    {
      name: 'Τύπος',
      render: (item) => VERTICAL_PROPERTY_TYPE_LABELS[item.type],
    },
    {
      name: 'Στάθμη',
      render: (item) => item.level || '-',
    },
    // {
    //   name: 'Εμβαδόν (μικτό)',
    //   render: (item) => item.grossArea
    //     ? (
    //       <span>
    //         {item.grossArea} m<sup>2</sup>
    //       </span>
    //     )
    //     : '-',
    // },
    // {
    //   name: 'Εμβαδόν (καθαρό)',
    //   render: (item) => item.netArea
    //     ? (
    //       <span>
    //         {item.netArea} m<sup>2</sup>
    //       </span>
    //     )
    //     : '-',
    // },
    {
      name: 'Αγοραστής',
      render: (item) => {
        let clientAgreements = item?.Agreements.filter(a => a?.client);

        if (clientAgreements?.length === 0) {
          return '-';
        }

        return clientAgreements?.[0] && (
          <Link to={`/clients/${clientAgreements?.[0]?.client?.id}`}>
            {clientAgreements?.[0]?.client?.name}
          </Link>
        );
      },
    },
    {
      name: '',
      render: (item) => (
        <div className="d-flex">
          <Button
            className="ms-auto"
            variant="outline-dark"
            onClick={() => showPropertyModal(item)}
            size="sm"
          >
            <PencilIcon className="ms-1" height={18} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        loadData={loadData}
        showDeleteButton
        addItemComponent={(
          <button
            className="btn btn-lg btn-primary ms-auto"
            onClick={() => showPropertyModal({ title: '' })}
            type="button"
          >
            <PlusIcon />
            Προσθήκη ιδιοκτησίας
          </button>
        )}
        model={model}
        searchContainerClass="shadow-none"
        tableContainerClass="shadow-none"
        striped
        hover
        columns={columns}
      />

      <VerticalPropertyModalForm
        initialValues={{
          projectId: project.id,
          // id: propertyModal?.property?.id,
          // title: propertyModal?.property?.title,
          // type: propertyModal?.property?.type,
          ...propertyModal?.property,
        }}
        onHide={hidePropertyModal}
        show={propertyModal.show}
        readOnly={propertyModal.readOnly}
        onSubmit={submitForm}
      />
    </>
  );
}

export default ProjectVerticalPropertiesTab;
