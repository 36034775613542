import React, { useCallback }  from  'react';
import { useDropzone } from 'react-dropzone';
import {
  DocumentIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button } from 'react-bootstrap';

import { API_URL } from '../../utils/axios';

const FileInput = props => {
  const { disabled, input, input: { value = null } } = props;

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    input.onChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    // accept: "image/jpeg, image/png"
  });

  const hasValue = input?.value;

  return (
    <>
      {!hasValue && (
        <div {...getRootProps()}>
          <input {...getInputProps()} {...props} />
          {isDragActive ? (
            <p className="drop-zone">Κάντε κλίκ εδώ ή σύρετε ένα αρχείο</p>
          ) : (
            <p className="drop-zone">Κάντε κλίκ εδώ ή σύρετε ένα αρχείο</p>
          )}
        </div>
      )}
    </>
  );
};

const InputField = (props) => {
  const {
    disabled = false,
    label,
    type,
    input: {
      name,
      ...rest
    },
    input,
    meta: {
      touched,
      error,
      submitError,
    },
    meta,
  } = props;
  return (
    <div className="mb-3">
      <label
        htmlFor={name}
        className="form-label"
      >
        {label}
      </label>

      {!disabled && (
        <FileInput {...props} />
      )}


      <div>
        {input?.value && input?.value.map && input?.value.map(file => (
          <div
            key={file?.name}
            className="d-inline-flex align-items-center"
          >
            <span
              className="d-inline-flex align-items-center"
            >
              <DocumentIcon
                width={25}
                className="me-1"
              />
              {file?.name}
            </span>

            <span className="d-flex align-items-center ms-3 cursor-pointer" onClick={() => input.onChange(undefined)}>
              <XMarkIcon width={20} />
            </span>
          </div>

        ))}
        {typeof input?.value === 'string' && input.value !== '' && (
          <div
            className="d-inline-flex align-items-center"
          >
            <a
              target="_blank"
              className="d-inline-flex align-items-center"
              href={`${API_URL}/${input?.value}`}
            >
              <DocumentIcon
                width={25}
                className="me-1"
              />
              {input?.value}
            </a>

            <span className="d-flex align-items-center ms-3 cursor-pointer" onClick={() => input.onChange(undefined)}>
              <XMarkIcon width={20} />
            </span>
          </div>
        )}
      </div>

      {(error || submitError) && touched && (
        <div className="text-danger my-1">{error || submitError}</div>
      )}
    </div>
  );
};

export default InputField;
