import React, { useRef }  from  'react';
import { Form } from 'react-final-form';
import { Modal, Button } from 'react-bootstrap';

import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import FileInput from  './../finalFormFields/FileInput';

const FileUploadModalForm = ({ readOnly, onHide, show, initialValues, onSubmit }) => {
  const isUpdate = initialValues?.id;
  const containerRef = useRef(null);
  let title = isUpdate ? 'Επεξεργασία' : 'Ανέβασμα αρχείου';
  if (readOnly) {
    title = 'Πληροφορίες αρχείου';
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit} ref={containerRef}>
            <Modal
              enforceFocus
              container={containerRef}
              size="lg"
              backdrop="static"
              show={show}
              onHide={onHide}>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-12">
                    <Field
                      type="text"
                      component={TextInput}
                      label="Όνομα αρχείου"
                      name="name"
                    />
                  </div>

                  <div className="col-12">
                    <Field
                      component={FileInput}
                      label="Αρχείο"
                      name="fileUrl"
                    />
                  </div>
                </div>


                <Field
                  rows={2}
                  component={TextAreaInput}
                  label="Σχόλια"
                  name="comments"
                />

                {submitError && <div className="error">{submitError}</div>}
              </Modal.Body>
              <Modal.Footer>
                {readOnly && (
                  <Button variant="secondary" onClick={onHide}>
                    Κλείσιμο
                  </Button>
                )}
                {!readOnly && (
                  <>
                    <Button variant="secondary" onClick={onHide}>
                      Ακύρωση
                    </Button>
                    <Button variant="primary" type="submit">
                      {isUpdate ? 'Αποθήκευση' : 'Καταχώρηση'}
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
          </form>
        )}
    />
  );
};

export default FileUploadModalForm;
