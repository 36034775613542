import React, { useEffect, useState }  from  'react';
import AsyncSelect from 'react-select/async';

import axiosClient from '../../utils/axios';

const AsyncSelectInput = ({
  searchUrl,
  getValueUrl,
  label,
  syncWhenChanged,
  multiselect = false,
  wrapperClass = 'mb-3',
  input: {
    name,
    value
  },
  options,
  input,
  meta: {
    initialValue,
    touched,
    error,
    submitError,
  },
  disabled,
  meta,
  onChange = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    loadDefaultOptions();
  }, [syncWhenChanged]);

  useEffect(() => {
    loadDefaultValue();
  }, [value]);

  const onValueChange = (item) => {
    if (!item) {
      if (multiselect) {
        input.onChange([])
      } else {
        input.onChange('');
      }
      onChange();
      return;
    }

    if (multiselect) {
      input.onChange(item.map(i => i.value).join(','))
    } else {
      input.onChange(item.value);
    }
  }

  const loadOptions = async (inputValue, pageSize = 5) => {
    if (!searchUrl) {
      return;
    }
    const pserchRes = await axiosClient.get(`/${searchUrl}?pageSize=${pageSize}&q=${inputValue}`);
    const items = pserchRes.data.map(p => ({
      value: p.id,
      label: p.title || p.name,
    }));
    return items;
  };

  const loadDefaultOptions = async () => {
    const options = await loadOptions('', 30);
    setDefaultOptions(options);
  }

  const loadDefaultValue = async () => {
    if (!searchUrl) {
      return;
    }
    setLoading(true);

    if (!value) {
      setLoading(false);
      return undefined;
    }

    if (multiselect) {
      if (Array.isArray(value)) {
        const items = await Promise.all(value.map(async id => {
          let url = `/${searchUrl}/${id}`;
          if (getValueUrl) {
            url = getValueUrl(id);
          }
          const itemRes = await axiosClient.get(url)
          return {
            value: itemRes.data.id,
            label: itemRes.data.title || itemRes.data.name,
          };
        }));

        setDefaultValue(items);
        setLoading(false);
      }
      setLoading(false);
    } else {
      let url = `/${searchUrl}/${value}`;
      if (getValueUrl) {
        url = getValueUrl(value);
      }
      setLoading(false);
      const itemRes = await axiosClient.get(url);
      const item = {
        value: itemRes.data.id,
        label: itemRes.data.title || itemRes.data.name,
      };
      setDefaultValue(item);
    }
  }

  if (!searchUrl) {
    return 'searchUrl not provided';
  }

  return (
    <div className={wrapperClass}>
      <label
        htmlFor={name}
        className="form-label"
      >
        {label}
      </label>

      {!loading && (
        <AsyncSelect
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable
          isDisabled={disabled}
          cacheOptions
          loadOptions={loadOptions}
          onChange={onValueChange}
          defaultOptions={defaultOptions}
          defaultValue={defaultValue}
          placeholder="Επιλέξτε..."
          isMulti={multiselect}
        />
      )}

      {(error || submitError) && touched && (
        <div className="text-danger my-1">{error || submitError}</div>
      )}
    </div>
  );
};

export default AsyncSelectInput;
