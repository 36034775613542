import React, { useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';

import { API_URL } from '../utils/axios';
import { formatDate } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { createDS, updateDS } from '../dataServices/transactions';
import FileUploadModalForm from './../components/forms/FileUploadModalForm';
import DataGrid from './DataGrid';

import {
  PlusIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline'

const ProjectFilesTab = ({ project }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const model = `projects/${project.id}/files`;
  const [modal, setModal] = useState({ show: false, model: null });

  const hideModal = () => {
    setModal({
      model: null,
      show: false,
      readOnly: false,
    });
  }

  const showModal = (model, readOnly = false) => {
    setModal({
      model,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('files', values.id, values));
    } else {
      dataService = dispatch(createDS('files', values));
    }

    return dataService
      .then(({ data, status }) => {
        if (status >= 400 ) {
          return data;
        } else {
          toastr.success('Έγινε αποθήκευση');
          hideModal();
          loadData();
        }
      });
  }

  const columns = [
    {
      name: 'Αρχείο',
      render: (item) => {
        if (!item.fileUrl) {
          return '-';
        }

        const tooltip = (
          <Tooltip id="tooltip">
            Προβολή παραστατικού
          </Tooltip>
        );

        return (
          <a
            className="d-inline-flex align-items-center fw-semibold"
            target="_blank"
            href={`${API_URL}/${item.fileUrl}`}
          >
            <span className="d-inline-flex align-items-center">
              <DocumentIcon height={20} className="me-1" />
              {item.name}
              </span>
          </a>
        );
      }
    },
    {
      name: 'Σχόλια',
      render: (item) => item.comments || '-',
    },
    {
      name: (<>Ημερ/νία<br /> δημιουργίας</>),
      render: (item) => formatDate(item.createdAt),
    },
  ];

  return (
    <>
      <DataGrid
        loadData={loadData}
        addItemComponent={(
          <button
            className="btn btn-lg btn-primary ms-auto"
            onClick={() => showModal({ amount: null })}
            type="button"
          >
            <PlusIcon />
            Ανέβασμα αρχείου
          </button>
        )}
        model={model}
        searchContainerClass="shadow-none"
        tableContainerClass="shadow-none"
        striped
        hover
        columns={columns}
        showDeleteButton
      />

      <FileUploadModalForm
        initialValues={{
          projectId: project.id,
          ...modal?.model,
        }}
        onHide={hideModal}
        show={modal.show}
        readOnly={modal.readOnly}
        onSubmit={submitForm}
      />
    </>
  );
}

export default ProjectFilesTab;
