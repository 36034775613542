//import { SOIL_TYPES_FETCHED } from "../actionTypes/actionTypes.js";

const initialState = {
  tbd: [],
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    // case SOIL_TYPES_FETCHED:
    //   return {
    //     ...state,
    //     soilTypes: action.payload,
    //   };

    default:
      return state;
  }
};

export default globalReducer;
