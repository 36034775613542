import { DEFAULT_DATE_FORMAT } from '../constants/const';
import { format as dateFnsFormat, parseISO, parse, isValid } from 'date-fns';


export const formatDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return '-';
  }

  if(!isValid(parseISO(date, 'yyyy-MM-dd'))) {
    return '-';
  }

  return dateFnsFormat(parseISO(date, 'yyyy-MM-dd'), format);
}

export const scrollToSelector = selecctor => {
  const element = document.querySelector(selecctor);
  if (!element) {
    return;
  }
  const offset = element.offsetTop;
  document.querySelector('.page').scrollTo({
    top: offset,
    behavior: "smooth"
  })
  // const element = document.querySelector(selecctor);
  // element.scrollTop = 0;
}

export const parseDateValue = (date) => {
  if (date === '') {
    return null;
  }

  return date;
}

export const formatMoneyAmount = (amount) => {
  if (!amount) {
    return  '-';
  }

  return new Intl.NumberFormat(
    'el-GR',
    {
      style: 'currency', currency: 'EUR',
    },
  ).format(amount);

}
