import React, { useRef, useState }  from  'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Modal, Button } from 'react-bootstrap';

import { formatDate } from '../../utils/utils';
import { DEFAULT_DATE_TIME_FORMAT } from '../../constants/const';
import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import SelectInput from  './../finalFormFields/SelectInput';
import AsyncSelectInput from  './../finalFormFields/AsyncSelectInput';
import FileInput from  './../finalFormFields/FileInput';

const AgreementModalForm = ({ onlyFor = null, readOnly, onHide, show, initialValues, onSubmit }) => {
  const isUpdate = initialValues?.id;
  const containerRef = useRef(null);
  let title = isUpdate ? 'Επεξεργασία' : 'Νέα συμφωνία';
  if (readOnly) {
    title = 'Πληροφορίες συμφωνίας';
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => {
          const { agreementType } = values;
          return (
            <form onSubmit={handleSubmit} ref={containerRef}>
              <Modal
                enforceFocus
                container={containerRef}
                size="lg"
                backdrop="static"
                show={show}
                onHide={onHide}>
                <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {initialValues.name && (
                    <h3 className="mb-3">
                      # {initialValues.name}
                    </h3>
                  )}

                  <div className="row">
                    <div className="col-12">
                      <Field
                        component={TextInput}
                        label="Τίτλος"
                        name="title"
                        type="text"
                        disabled={readOnly}
                      />
                    </div>
                  </div>

                  {!onlyFor && (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <Field
                          component={SelectInput}
                          label="Αφορά"
                          name="agreementType"
                          type="text"
                          options={[
                            { name: 'Πελάτη', value: 'client' },
                            { name: 'Εργολάβο', value: 'contractor' },
                            { name: 'Προμηθευτή', value: 'supplier' },
                          ]}
                          disabled={readOnly}
                        />
                        <OnChange name="agreementType">
                          {(value, previous) => {
                            if (value === 'client') {
                              form.change('contractorId', null);
                              form.change('supplierId', null);
                            } else if (value === 'contractor') {
                              form.change('clientId', null);
                              form.change('supplierId', null);
                            } else if (value === 'supplier') {
                              form.change('contractorId', null);
                              form.change('clientId', null);
                            }
                          }}
                        </OnChange>
                      </div>

                      {agreementType && agreementType === 'client' && (
                        <div className="col-12 col-lg-6">
                          <Field
                            component={AsyncSelectInput}
                            label="Πελάτης"
                            name="clientId"
                            type="text"
                            disabled={readOnly}
                            searchUrl="clients"
                          />
                        </div>
                      )}

                      {agreementType && agreementType === 'supplier' && (
                        <div className="col-12 col-lg-6">
                          <Field
                            component={AsyncSelectInput}
                            label="Προμηθευτής"
                            name="supplierId"
                            type="text"
                            disabled={readOnly}
                            searchUrl="suppliers"
                            syncWhenChanged={agreementType}
                          />
                        </div>
                      )}

                      {agreementType && agreementType === 'contractor' && (
                        <div className="col-12 col-lg-6">
                          <Field
                            component={AsyncSelectInput}
                            label="Εργολάβος"
                            name="contractorId"
                            type="text"
                            disabled={readOnly}
                            searchUrl="contractors"
                            syncWhenChanged={agreementType}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <Field
                        component={AsyncSelectInput}
                        label="Έργο"
                        name="projectId"
                        type="text"
                        disabled={readOnly}
                        searchUrl="projects"
                        syncWhenChanged={agreementType}
                      />
                    </div>

                    <div className="col-12 col-lg-6">
                      <Field
                        component={TextInput}
                        prefix={<span>&euro;</span>}
                        label="Ποσό"
                        name="amount"
                        type="number"
                        step="0.01"
                        disabled={readOnly}
                      />
                    </div>

                    <div className="col-12">
                      {values.projectId && (
                        <Field
                          component={AsyncSelectInput}
                          syncWhenChanged={values.projectId}
                          label="Κάθετες ιδιοκτησίες"
                          name="verticalProperties"
                          type="text"
                          disabled={readOnly}
                          searchUrl={`projects/${values.projectId}/vertical-properties`}
                          getValueUrl={(value) => `vertical-properties/${value}`}
                          multiselect
                        />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-6">
                      {/*<Field*/}
                      {/*  type="date"*/}
                      {/*  component={DateSelectField}*/}
                      {/*  label="Ημερ/νία συναλλαγής"*/}
                      {/*  name="date"*/}
                      {/*  required*/}
                      {/*/>*/}
                    </div>

                    <div className="col-12 col-lg-6">

                    </div>
                  </div>

                  <Field
                    rows={5}
                    component={TextAreaInput}
                    label="Περιγραφή"
                    name="comments"
                    disabled={readOnly}
                  />


                  <div className="row">
                    <div className="col-12">
                      <Field
                        component={FileInput}
                        label="Συνοδευτικό αρχείο"
                        name="fileUrl"
                        disabled={readOnly}
                      />
                    </div>
                  </div>

                  {values?.id && (
                    <>
                      <div className="text-muted opacity-50 fs-6 mb-2 text-end">
                        Καταχωρήθηκε:
                        <span className="ms-1">
                          {formatDate(values.createdAt, DEFAULT_DATE_TIME_FORMAT)}
                        </span>
                      </div>

                      <div className="text-muted opacity-50 fs-6 mb-2 text-end">
                        Τελευταία αλλαγή:
                        <span className="ms-1">
                          {formatDate(values.updatedAt, DEFAULT_DATE_TIME_FORMAT)}
                        </span>
                      </div>
                    </>
                  )}

                  {submitError && <div className="error">{submitError}</div>}
                </Modal.Body>
                <Modal.Footer>
                  {readOnly && (
                    <Button variant="secondary" onClick={onHide}>
                      Κλείσιμο
                    </Button>
                  )}
                  {!readOnly && (
                    <>
                      <Button variant="secondary" onClick={onHide}>
                        Ακύρωση
                      </Button>
                      <Button variant="primary" type="submit">
                        {isUpdate ? 'Αποθήκευση' : 'Καταχώρηση'}
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Modal>
            </form>
          );
        }}
    />
  );
};

export default AgreementModalForm;
