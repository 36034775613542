import React, { useRef }  from  'react';
import { Form } from 'react-final-form';
import { Modal, Button } from 'react-bootstrap';

import { formatDate } from '../../utils/utils';
import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';
import { DEFAULT_DATE_TIME_FORMAT } from '../../constants/const';


const UserModalForm = ({ searchUrl, readOnly, onHide, show, initialValues, onSubmit }) => {
  const isUpdate = initialValues?.id;
  const containerRef = useRef(null);
  let title = isUpdate ? 'Επεξεργασία' : 'Νέα πληρωμή';
  if (readOnly) {
    title = 'Πληροφορίες συναλλαγής';
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit} ref={containerRef}>
            <Modal
              enforceFocus
              container={containerRef}
              size="lg"
              backdrop="static"
              show={show}
              onHide={onHide}>
              <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col-12">
                    <Field
                      component={TextInput}
                      label="Όνομα"
                      name="firstName"
                      type="text"
                      required
                      disabled={readOnly}
                    />
                  </div>

                  <div className="col-12">
                    <Field
                      component={TextInput}
                      label="Επώνυμο"
                      name="lastName"
                      type="text"
                      required
                      disabled={readOnly}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Field
                      component={TextInput}
                      label="Email"
                      name="email"
                      type="email"
                      required
                      disabled={readOnly}
                    />
                  </div>

                  {!values?.id && (
                    <div className="col-12">
                      <Field
                        component={TextInput}
                        label="Κωδικός πρόσβασης"
                        name="password"
                        type="password"
                        required
                        disabled={readOnly}
                      />
                    </div>
                  )}
                </div>

                {values?.id && (
                  <>
                    <div className="text-muted opacity-50 fs-6 mb-2 text-end">
                      Καταχωρήθηκε:
                      <span className="ms-1">
                        {formatDate(values.createdAt, DEFAULT_DATE_TIME_FORMAT)}
                      </span>
                    </div>

                    <div className="text-muted opacity-50 fs-6 text-end">
                      Τελευταία αλλαγή:
                      <span className="ms-1">
                        {formatDate(values.updatedAt, DEFAULT_DATE_TIME_FORMAT)}
                      </span>
                    </div>
                  </>
                )}

                {submitError && <div className="error">{submitError}</div>}
              </Modal.Body>
              <Modal.Footer>
                {readOnly && (
                  <Button variant="secondary" onClick={onHide}>
                    Κλείσιμο
                  </Button>
                )}
                {!readOnly && (
                  <>
                    <Button variant="secondary" onClick={onHide}>
                      Ακύρωση
                    </Button>
                    <Button variant="primary" type="submit">
                      {isUpdate ? 'Αποθήκευση' : 'Καταχώρηση'}
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
          </form>
        )}
    />
  );
};

export default UserModalForm;
