import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import auth from './reducers/authReducer';
import globals from './reducers/globalReducer';
import app from './reducers/appReducer';

const combinedReducers = combineReducers({
  app,
  auth,
  globals,
});

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers)

  return createStore(combinedReducers, preloadedState, composedEnhancers)}
