const SelectInput = ({
  label,
  type,
  size,
  wrapperClass = 'mb-3',
  input: {
    name,
      ...rest
  },
  options,
  input,
  meta: {
    touched,
    error,
    submitError,
  },
  disabled,
  meta,
}) => (
  <div className={wrapperClass}>
    <label
      htmlFor={name}
      className="form-label"
    >
      {label}
    </label>

    <select
      name={name}
      disabled={disabled}
      className={`form-control form-select ${size ? `form-control-${size}` : '' }`}
      {...rest}
    >
      <option value="">Επιλέξτε...</option>
      {options && options.map(opt => (
        <option
          disabled={opt.disabled}
          key={opt.value}
          value={opt.value}
        >
          {opt.name}
        </option>
      ))}
    </select>

    {(error || submitError) && touched && (
      <div className="text-danger my-1">{error || submitError}</div>
    )}
  </div>
);

export default SelectInput;
