import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import toastr from 'toastr';
import { Tab, Tabs, Card } from 'react-bootstrap';
import {
  ChevronRightIcon
} from '@heroicons/react/24/outline';

import axiosClient from '../utils/axios';
import DeleteModal from './../components/DeleteModal';
import DefaultLayout from './../layouts/DefaultLayout';
import ClientAgreementsTab from './../components/ClientAgreementsTab';
import ClientTransactionsTab from './../components/ClientTransactionsTab';
import { updateDS } from '../dataServices/model';

const ClientView = () => {
  const [activeTab, setActiveTab] = useState('agreements');
  const [client, setClient] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams()

  useEffect(() => {
    fetchClient();
  }, []);

  const fetchClient = async () => {
    const client = await axiosClient.get(`/clients/${clientId}`);
    setClient(client.data);
  };

  const onSubmit = async (values) => {
    return dispatch(updateDS('clients', client.id, values))
      .then(({ data }) => {
        if (data?._error) {
          toastr.error(data?._error);
          return data;
        } else {
          navigate('/clients');
          toastr.success('Έγινε αποθήκευση');
        }
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <DefaultLayout
      title={(
        <>
          <Link to="/clients" className="text-muted">
            Πελάτες
          </Link>
          <ChevronRightIcon height={18} className="mx-1" />
          {client?.lastName} {client?.firstName}
        </>
      )}
    >
      <div className="row">
        <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
          <Card
            className="border-0 shadow bg-white"
          >
            <Card.Header className="bg-white">
              <h3 className="my-2">Στοιχεία πελάτη</h3>
            </Card.Header>

            <Card.Body
              className="details-list "
            >
              <div className="details-list-item">
                <div className="details-label">Όνομα</div>
                <div className="details-value">{client?.lastName} {client?.firstName}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Τηλέφωνο</div>
                <div className="details-value">{client?.phone}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Email</div>
                <div className="details-value">{client?.email || '-'}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Διεύθυνση</div>
                <div className="details-value">{client?.address || '-'}</div>
              </div>

              <div className="details-list-item">
                <div className="details-label">Σημειώσεις</div>
                <div className="details-value">
                  <p>
                    {client?.notes || '-'}
                  </p>
                </div>
              </div>

              <hr />

              <Link
                className="mb-3 ms-auto d-inline-flex w-auto btn btn-secondary btn-sm"
                to={`/clients/${client?.id}/edit`}
              >
                Επεξεργασία στοιχείων
              </Link>

              <DeleteModal
                title="Διαγραφή πελάτη"
                model={client}
                modelName="clients"
                onDelete={() => navigate('/clients')}
              />
            </Card.Body>
          </Card>
        </div>

        <div className="col-12 col-md-8 col-lg-9">
          <div className="bg-white rounded shadow p-3">
            {client && (
              <Tabs
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
                className="mb-3"
                unmountOnExit
              >
                <Tab eventKey="agreements" title="Συμφωνίες">
                  <ClientAgreementsTab
                    client={client}
                  />
                </Tab>

                <Tab eventKey="payments" title="Πληρωμές">
                  <ClientTransactionsTab
                    client={client}
                  />
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default ClientView;
