import {
  LIST_FETCHED
} from "../actionTypes/actionTypes.js";

const initialState = {
  lists: {},
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_FETCHED:
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.payload.model]: {
            data: action.payload.data,
            pagination: action.payload.pagination,
            extra: action.payload.extra,
          }
        },
      };

    default:
      return state;
  }
};

export default appReducer;
