import axios from 'axios';

// export const API_URL = '/api';
export const API_URL = 'https://kouklakis.palioudakis.com/';
const axiosInstance = axios.create({
  baseURL: API_URL,
});
axiosInstance.interceptors.request.use(
  config => {
    const authData = localStorage.getItem('auth');
    if (authData) {
      const { data: { token } } = JSON.parse(authData);
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a 401 response interceptor
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    localStorage.removeItem('auth');
    window.location = '/login';
  } else {
    return Promise.reject(error);
  }
});

export default axiosInstance;
