import React from 'react';
import { Link } from "react-router-dom";
import {
  PlusIcon,
} from '@heroicons/react/24/outline'

import DefaultLayout from './../layouts/DefaultLayout';
import DataGrid from './../components/DataGrid';

const columns = [
  {
    name: 'Όνομα',
    render: (item) => (
      <Link className="list-item-link" to={`/clients/${item.id}`}>
        {item.lastName} {item.firstName}
      </Link>
    ),
  },
  {
    name: 'Τηλέφωνο',
    render: (item) => item.phone || '-',
  },
  {
    name: 'Email',
    render: (item) => item.email || '-',
  },
];

const ClientsView = () => {
  return (
    <DefaultLayout
      title="Πελάτες"
      cta={(
        <Link
          to="/clients/create"
          className="btn btn-primary d-inline-block"
          type="button"
        >
          <PlusIcon />
          Νέος πελάτης
        </Link>
      )}
    >
      <DataGrid
        model="clients"
        striped
        hover
        responsive
        columns={columns}
      />
    </DefaultLayout>
  );
}

export default ClientsView;
