export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';

export const VERTICAL_PROPERTY_TYPES = {
  'apartment': 'apartment',
  'parking': 'parking',
  'storage-space': 'storage-space',
};

export const VERTICAL_PROPERTY_TYPE_LABELS = {
  'apartment': 'Διαμέρισμα',
  'parking': 'Πάρκινγκ',
  'storage-space': 'Αποθήκη',
};

export const TRANSACTION_TYPES = {
  CASH: 'cash',
  CHEQUE: 'cheque',
  BANK_WIRE: 'bank-wire',
};
export const TRANSACTION_TYPE_LABELS = {
  [TRANSACTION_TYPES.CASH]: 'Μετρητά',
  [TRANSACTION_TYPES.CHEQUE]: 'Επιταγή',
  [TRANSACTION_TYPES.BANK_WIRE]: 'Τραπεζικό έμβασμα',
};

export const MEASUREMENT_CATEGORIES = {
  BRICKS: 'bricks',
  SMEARS: 'smears',
  CONCRETE: 'concrete',
  PARAPET: 'parapet',
};
export const MEASUREMENT_CATEGORIES_LABELS = {
  [MEASUREMENT_CATEGORIES.BRICKS]: 'Τούβλα',
  [MEASUREMENT_CATEGORIES.SMEARS]: 'Επιχρίσματα',
  [MEASUREMENT_CATEGORIES.CONCRETE]: 'Σκυρόδεμα',
  [MEASUREMENT_CATEGORIES.PARAPET]: 'Εμφανή Στηθαία',
};
export const MEASUREMENT_CATEGORY_VARIATIONS = {
  [MEASUREMENT_CATEGORIES.BRICKS]: {
    TOUVLINA: 'touvlina',
    DOMIKI_MONI: 'domiki-moni',
    DOMIKI_DIPLI: 'domiki-dipli',
    SENAZ_MONA: 'senaz-mona',
    SENAZ_DIPLA: 'senaz-dipla',
  }
};
export const MEASUREMENT_CATEGORY_VARIATIONS_LABELS = {
  [MEASUREMENT_CATEGORIES.BRICKS]: {
    [MEASUREMENT_CATEGORY_VARIATIONS[MEASUREMENT_CATEGORIES.BRICKS].TOUVLINA]: 'Τουβλίνα',
    [MEASUREMENT_CATEGORY_VARIATIONS[MEASUREMENT_CATEGORIES.BRICKS].DOMIKI_MONI]: 'Δομική μονή',
    [MEASUREMENT_CATEGORY_VARIATIONS[MEASUREMENT_CATEGORIES.BRICKS]. DOMIKI_DIPLI]: 'Δομική διπλή',
    [MEASUREMENT_CATEGORY_VARIATIONS[MEASUREMENT_CATEGORIES.BRICKS].SENAZ_MONA]: 'Σενάζ μονά',
    [MEASUREMENT_CATEGORY_VARIATIONS[MEASUREMENT_CATEGORIES.BRICKS].SENAZ_DIPLA]: 'Σενάζ διπλά',
  }
};

