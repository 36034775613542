import React from 'react';
import Modal from 'react-bootstrap/Modal';

import SearchForm from './forms/SearchForm';
const DataGridSearch = ({ pushSearchButton, className, onSubmit, initialValues, showSearch, filters = [] }) => {
  const classNames = ['search-container bg-white rounded shadow', className];
  return (
    <div className={classNames.join(' ')}>
      <SearchForm
        showSearch={showSearch}
        filters={filters}
        pushSearchButton={pushSearchButton}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default DataGridSearch;
