import toastr from 'toastr';

import axiosClient from '../utils/axios';
import { parseDateValue } from '../utils/utils';

import {
  LIST_FETCHED,
} from "../actionTypes/actionTypes";

export const fetchListDS = (model, search) => async (dispatch) => {
  await axiosClient
    .get(`/${model}${search ? search : ''}`)
    .then(({ data, headers }) => {
      dispatch({
        type: LIST_FETCHED,
        payload: {
          model,
          data,
          pagination: {
            currentPage: parseInt(headers['x-current-page'], 10),
            pageSize: parseInt(headers['x-page-size'], 10),
            totalCount: parseInt(headers['x-total-count'], 10),
          },
          extra: {
            totalAmount: headers['x-total-amount'] && parseFloat(headers['x-total-amount'], 10),
            totalAmounts: headers['x-total-amounts'] && JSON.parse(headers['x-total-amounts']),
          }
        },
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const createDS = (model, values, options) => async (dispatch) => {
  return await axiosClient
    .post(`/${model}/`, {
      ...values,
      date: parseDateValue(values?.date),
      documentDate: parseDateValue(values?.documentDate),
    })
    .then((response) => {
      return response;
    })
    .catch(function ({ response }) {
      return response;
    });
};

export const updateDS = (model, id, values, options) => async (dispatch) => {
  return await axiosClient
    .put(`/${model}/${id}`, {
      ...values,
      date: parseDateValue(values?.date),
      documentDate: parseDateValue(values?.documentDate),
    })
    .then((response) => {
      return response;
    })
    .catch(function ({ response }) {
      return response;
    });
};

export const deleteDS = (model, id) => async (dispatch) => {
  return await axiosClient
    .delete(`/${model}/${id}`)
    .then(({ data }) => {

    })
    .catch(function (err) {
      return 'error';
    });
};

