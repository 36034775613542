import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import { DEFAULT_DATE_TIME_FORMAT } from '../constants/const';
import { scrollToSelector } from '../utils/utils';
import { formatDate } from '../utils/utils';
import { fetchListDS } from '../dataServices/model';
import { createDS, updateDS } from '../dataServices/users';
import DataGrid from './DataGrid';

import {
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'

const SettingsLogsTab = () => {
  const model = 'logs';

  const dispatch = useDispatch();
  const { search } = useLocation();
  const listExtras = useSelector((state) => state?.app?.lists?.[model]?.extra);

  const [modal, setModal] = useState({ show: false, model: null });

  const hideModal = () => {
    setModal({
      model: null,
      show: false,
      readOnly: false,
    });
  }

  const showModal = (model, readOnly = false) => {
    setModal({
      model,
      show: true,
      readOnly,
    });
  }

  const loadData = () => {
    dispatch(fetchListDS(model, search)).then(() => {
      scrollToSelector('.table-container');
    });
  };

  const submitForm = (values) => {
    let dataService;
    if (values.id) {
      dataService = dispatch(updateDS('logs', values.id, values));
    } else {
      dataService = dispatch(createDS('logs', values));
    }

    return dataService
      .then(({ data, status }) => {
        if (status >= 400 ) {
          return data;
        } else {
          toastr.success('Έγινε αποθήκευση');
          hideModal();
          loadData();
        }
      });
  }

  const columns = [
    {
      name: 'Αφορά',
      render: (item) => {
        let
          to = '',
          title = '',
          linkText = '';

        if (item?.clientId) {
          to = `/clients/${item.clientId}`;
          title = 'Πελάτης';
          linkText = `${item?.client?.firstName} ${item?.client?.lastName}`;
        }

        if (item?.projectId) {
          to = `/projects/${item.projectId}`;
          title = 'Έργο';
          linkText = item?.project?.title;
        }

        if (item?.supplierId) {
          to = `/suppliers/${item.supplierId}`;
          title = 'Προμηθευτής';
          linkText = item?.supplier?.name;
        }

        if (item?.contractorId) {
          to = `/contractors/${item.contractorId}`;
          title = 'Εργολάβος';
          linkText = item?.contractor?.name;
        }

        if (item?.transactionId) {
          to = null;
          title = 'Συναλλαγή';
          linkText = '';
        }

        if (item?.agreementId) {
          to = null;
          title = 'Συμφωνία';
          linkText = '';
        }

        return (
          <>
            {title}<br />
            <Link to={to}>{linkText}</Link>
          </>
        );
      },
    },
    {
      name: 'Αλλαγές',
      render: ({ changes }) => changes?.map(({ attribute, before, after }) => (
        <div>
          <span className="text-muted">{attribute}:</span>
          <br />
          <span style={{ textDecoration: 'line-through' }}>{before || '-'}</span> => {after || '-'}
        </div>
      )),
    },
    {
      name: 'Από',
      render: (item) => `${item?.user?.firstName} ${item?.user?.lastName}`,
    },
    {
      name: 'Ημερ/νία αλλαγής',
      render: (item) => formatDate(item.createdAt, DEFAULT_DATE_TIME_FORMAT),
    },
  ];

  return (
    <>
      <DataGrid
        showSearch={false}
        loadData={loadData}
        model={model}
        searchContainerClass="shadow-none"
        tableContainerClass="shadow-none"
        striped
        hover
        columns={columns}
      />
    </>
  );
}

export default SettingsLogsTab;

